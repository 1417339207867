'use client';

import useEmblaCarousel from 'embla-carousel-react';
import Image from 'next/image';
import {convertEnglishNumbersToArabic, getImageSrc} from '@/common/helpers';
import {useLocale} from 'next-intl';
import {FaPlus} from 'react-icons/fa';
import {useApplyCartActions} from '@/hooks';
import {useState} from 'react';
import {Tab, Tabs} from '@heroui/react';

const RecommendedAddOns = ({addOns, t}) => {
  const [emblaRef] = useEmblaCarousel();
  const locale = useLocale();
  const [selectedTab, setSelectedTab] = useState(null);

  const {applyCartAction} = useApplyCartActions();

  const handleAddingToCart = product => {
    applyCartAction('add', product);
  };

  return (
    <div className="mb-6">
      <Tabs
        aria-label="Options"
        variant="underlined"
        selectedKey={selectedTab}
        fullWidth
        onSelectionChange={setSelectedTab}
        classNames={{
          tabContent:
            'text-[13px] md:text-[16px]  group-data-[selected=true]:text-persianIndigo font-secondary font-normal text-persianIndigo',
          tabList: 'gap-0 border-b-1 border-thistle pb-[30px]',
          cursor: 'w-[102%] bottom-[-30px] bg-persianIndigo',
        }}>
        {addOns?.taxons?.map(taxon => (
          <Tab key={taxon.id} title={taxon.name}>
            <div className="flex items-center justify-between">
              <div className="embla overflow-hidden" ref={emblaRef}>
                <div
                  className={`${
                    locale === 'ar' ? 'flex-row-reverse' : 'flex-row'
                  } embla__container flex gap-4 sm:gap-[2rem]`}>
                  {taxon.products?.map((product, index) => {
                    const imageSrc =
                      product?.images?.[0]?.attributes?.original_url;
                    return (
                      <div
                        className={`embla__slide ml-[0.5rem] mt-[0.4rem]`}
                        key={index}>
                        <div className="relative flex h-[5rem] w-[8.125rem] items-center justify-center sm:h-[11.5rem] sm:w-[10.5rem]">
                          <Image
                            src={imageSrc}
                            width={600}
                            height={400}
                            alt="add-on"
                            className="h-full"
                          />
                          <div
                            className="absolute right-0 top-[-0.4rem] flex h-8 w-[2.5rem] cursor-pointer items-center justify-center rounded-md border-2 border-white bg-persianIndigo sm:w-8"
                            onClick={() =>
                              handleAddingToCart(product.variants?.[0])
                            }>
                            <FaPlus color="white" size={15} />
                          </div>
                        </div>
                        <div className="rounded-bl-lg rounded-br-lg pt-4">
                          <p className="font-main text-[12px] font-normal text-persianIndigo xl:text-[0.7vw]">
                            {convertEnglishNumbersToArabic(
                              Number(product.price),
                              locale,
                            )}{' '}
                            {t('sar')}
                          </p>
                          <p className="font-secondary text-[14px] font-normal text-persianIndigo xl:text-[0.9vw]">
                            {product.name}
                          </p>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </Tab>
        ))}
      </Tabs>
    </div>
  );
};

export default RecommendedAddOns;
