'use client';

import {showErrorToast} from '@/common';
import {LoadingView} from '@/components/common';
import {useRouter} from '@/hooks';
import {getIsAddingNewCard} from '@/selectors';
import {useCompleteCheckoutMutation} from '@/services';
import {resetCarts} from '@/slices';
import {useTranslations} from 'next-intl';
import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';

const CompletePayment = () => {
  const dispatch = useDispatch();
  const router = useRouter();
  const t = useTranslations();

  const isAddingNewCard = useSelector(getIsAddingNewCard);

  const [completeCheckout] = useCompleteCheckoutMutation();

  useEffect(() => {
    setTimeout(async () => {
      const response = await completeCheckout();

      if (
        response?.data?.data?.attributes?.state === 'complete' &&
        response?.data?.data?.attributes?.payment_state === 'paid'
      ) {
        if (!isAddingNewCard) {
          dispatch(resetCarts());
        }
        router.replace('/rating');
      } else {
        router.replace('/checkout/4');
        showErrorToast(t('payment_fail'));
      }
    }, 5000);
  }, []);
  return <LoadingView />;
};

export default CompletePayment;
