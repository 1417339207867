import {useGoogleLogin} from '@react-oauth/google';
import {useDispatch} from 'react-redux';
import {showErrorToast} from '@/common';
import {
  setGoogleToken,
  setIsAddingNewCard,
  setIsGuestPaymentStep,
} from '@/slices';
import {useAssociateCartMutation, useSocialMediaAuthMutation} from '@/services';
import {useTranslations} from 'next-intl';

const useGoogleAuth = (onSuccessCallback, onErrorCallback) => {
  const dispatch = useDispatch();
  const [googleLogin, {isLoading: isGoogleSignUpLoading}] =
    useSocialMediaAuthMutation();
  const login = useGoogleLogin({
    onSuccess: async data => {
      if (onSuccessCallback) {
        await onSuccessCallback(
          data,
          dispatch,
          googleLogin,
          isGoogleSignUpLoading,
        );
      }
    },
    flow: 'auth-code',
    onError: error => {
      if (onErrorCallback) {
        showErrorToast(error);
      }
    },
  });

  return {
    login,
  };
};

export const useGoogleSRLogin = (onClosingLogin, cart) => {
  const t = useTranslations();
  const [associateCart] = useAssociateCartMutation();
  const {login} = useGoogleAuth(async (data, dispatch, googleLogin) => {
    onClosingLogin();
    const response = await googleLogin({
      google_token: data.code,
      grant_type: 'password',
    });
    if (response?.error) {
      showErrorToast(t('please_signup_first'));
    } else {
      dispatch(setIsGuestPaymentStep(false));
      dispatch(setIsAddingNewCard(false));
      dispatch(setGoogleToken(data.code));
      await associateCart({
        guest_order_token: cart?.token,
      });
    }
  });

  return {login};
};

export const useGoogleSRSignUp = () => {
  const {login} = useGoogleAuth((data, dispatch) => {
    dispatch(setGoogleToken(data.code));
  });
  return {login};
};
