import {facebook} from '@/assets';
import {showErrorToast} from '@/common';
import {
  setFacebookToken,
  setIsAddingNewCard,
  setIsGuestPaymentStep,
} from '@/slices';
import FacebookLogin from '@greatsumini/react-facebook-login';
import {useTranslations} from 'next-intl';
import Image from 'next/image';
import {useDispatch} from 'react-redux';

const FacebookAuth = ({
  handleFacebookLogin,
  associateCart,
  onClosingLogin,
  cart,
}) => {
  const dispatch = useDispatch();
  const t = useTranslations();
  return (
    <FacebookLogin
      appId={process.env.NEXT_PUBLIC_FACEBOOK_APP_ID}
      onSuccess={async response => {
        dispatch(setFacebookToken(response?.accessToken));
        if (handleFacebookLogin) {
          const facebookLoginResponse = await handleFacebookLogin({
            facebook_token: response?.accessToken,
            grant_type: 'password',
          });
          if (facebookLoginResponse?.error) {
            onClosingLogin();
            showErrorToast(t('please_signup_first'));
          } else {
            onClosingLogin();
            dispatch(setIsGuestPaymentStep(false));
            dispatch(setIsAddingNewCard(false));
            await associateCart({
              guest_order_token: cart?.token,
            });
          }
        }
      }}
      render={({onClick, logout}) => (
        <Image
          src={facebook}
          width={37}
          height={37}
          alt="facebook icon"
          className="cursor-pointer"
          onClick={onClick}
        />
      )}
    />
  );
};

export default FacebookAuth;
