'use client';

import {commonDisableCachingParameters, showErrorToast} from '@/common';
import {
  getCart,
  getIsApplePayment,
  getIsGuestPaymentStep,
  getToken,
} from '@/selectors';
import {useGetUserQuery, useLazyGetPaymentMethodsQuery} from '@/services';
import {usePathname, useRouter} from '@/utils/navigation';
import {useLocale, useTranslations} from 'next-intl';
import {useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {CustomButton} from '@/components/common';
import {setIsFinishingPayment} from '@/slices';
import {useState} from 'react';
import {
  TapCard,
  Currencies,
  Direction,
  Edges,
  Locale,
  Theme,
  tokenize,
} from '@tap-payments/card-sdk';

const GoSellElement = ({
  handleTransactionComplete,
  handlePayment,
  isCreatingPayment,
  isCreatingPaymentWithStoreCredits,
}) => {
  const locale = useLocale();
  const router = useRouter();
  const pathName = usePathname();
  const t = useTranslations();
  const dispatch = useDispatch();
  const isGuestPaymentStep = useSelector(getIsGuestPaymentStep);
  const isApplePayment = useSelector(getIsApplePayment);
  const token = useSelector(getToken);
  const [getPaymentMethods] = useLazyGetPaymentMethodsQuery();

  const [isPaymentSDKLoading, setIsPaymentSDKLoading] = useState(true);
  const {data: user} = useGetUserQuery(
    {locale},
    {
      skip: isGuestPaymentStep || !token,
      ...commonDisableCachingParameters,
    },
  );

  const cart = useSelector(getCart);

  const customer = useMemo(
    () => ({
      id: user?.data?.attributes?.tap_payment_customer_id || '',
      name: [
        {
          first: user?.data?.attributes?.first_name || 'N/A',
          last: user?.data?.attributes?.last_name || 'N/A',
        },
      ],
      contact: {
        email: user?.data?.attributes?.email || 'support@springrose.com',
        phone: {
          countryCode: '966',
          number: user?.data?.attributes?.phone_number || '545833108',
        },
      },
    }),
    [user],
  );

  const handleError = error => {
    showErrorToast(error);
    handleStopingLoading();
    router.replace('/checkout/3');
  };

  const handleStopingLoading = () => {
    setIsPaymentSDKLoading(false);
  };
  const handleSuccess = async data => {
    dispatch(setIsFinishingPayment(true));
    const paymentMethodsResponse = await getPaymentMethods();
    await handlePayment(paymentMethodsResponse.data);
    handleTransactionComplete(data);
  };
  if (!pathName.includes('checkout')) {
    return null;
  }
  return (
    <>
      <TapCard
        showLoader={isPaymentSDKLoading}
        publicKey={process.env.NEXT_PUBLIC_TAP_PAYMENT_PUBLIC_KEY}
        merchant={{
          id: process.env.NEXT_PUBLIC_MERCHANT_ID,
        }}
        transaction={{
          amount: cart?.total,
          currency: Currencies.SAR,
        }}
        customer={customer}
        acceptance={{
          supportedBrands: ['AMEX', 'VISA', 'MASTERCARD', 'MADA'],
          supportedCards: ['CREDIT', 'DEBIT'],
        }}
        fields={{
          cardHolder: true,
        }}
        addons={{
          displayPaymentBrands: true,
          loader: true,
          saveCard: false,
          tokenize: true,
        }}
        interface={{
          locale: Locale.EN,
          theme: Theme.LIGHT,
          edges: Edges.CURVED,
          direction: Direction.LTR,
        }}
        onReady={handleStopingLoading}
        onError={error => handleError(error)}
        onSuccess={data => handleSuccess(data)}
      />
      {!isApplePayment && (
        <CustomButton
          btnStyles="mt-5 w-full"
          onClickHandling={tokenize}
          isLoading={isCreatingPayment || isCreatingPaymentWithStoreCredits}
          value={t('place_order')}
        />
      )}
    </>
  );
};

export default GoSellElement;
