'use client';
import React from 'react';
import Image from 'next/image';
import {cardChip, masterCard, signal, visa} from '@/assets';
import {useTranslations} from 'next-intl';

const CreditCardsSlide = ({data, dataIndex, isCenterSlide}) => {
  const t = useTranslations();

  return (
    <div>
      <div
        className={`relative h-[8rem] w-[15rem] rounded-[1rem] bg-gradient-to-r ${isCenterSlide ? 'opacity-100' : 'opacity-60'} cursor-pointer from-persianIndigo to-royalPurple`}>
        {data[dataIndex]?.cc_type.toLowerCase() === 'visa' ? (
          <Image
            src={visa}
            width={50}
            height={50}
            alt="visa logo"
            className="absolute right-[1rem] top-[1rem]"
          />
        ) : (
          <Image
            src={masterCard}
            width={50}
            height={50}
            alt="visa logo"
            className="absolute right-[1rem] top-[1rem]"
          />
        )}
        {}
        <span className="absolute left-[1rem] top-[1rem] font-secondary text-[10px] font-normal text-white">
          {data[dataIndex]?.default && t('default')}
        </span>
        <div className="absolute left-[1rem] top-[30%] flex items-center gap-2">
          <Image src={cardChip} width={25} height={25} alt="chip" />
          <Image src={signal} width={10} height={10} alt="signal" />
        </div>
        <p className="absolute left-[1rem] top-[50%] font-secondary text-[15px] font-extrabold text-white">
          {`xxxx xxxx xxxx ${data[dataIndex]?.last_digits}`}
        </p>
        <div className="absolute left-0 top-[68%] mx-[1rem] flex w-[13rem] items-center justify-between">
          <p className="font-secondary text-[10px] font-normal text-white">
            {t('name')}
          </p>
          <p className="font-secondary text-[10px] font-normal text-white">
            {t('exp_date')}
          </p>
        </div>
        <div className="absolute left-0 top-[80%] mx-[1rem] flex w-[13rem] items-center justify-between">
          <p className="font-secondary text-[13px] font-extrabold text-white">
            {data[dataIndex]?.name}
          </p>
          <p className="font-secondary text-[13px] font-extrabold text-white">
            {data[dataIndex]?.month < 10
              ? `0${data[dataIndex]?.month}/${data[dataIndex]?.year}`
              : `${data[dataIndex]?.month}/${data[dataIndex]?.year}`}
          </p>
        </div>
      </div>
    </div>
  );
};
export default CreditCardsSlide;
