'use client';

import {useTranslations} from 'next-intl';
import Image from 'next/image';
import {product2} from '@/assets';
import {CustomButton} from '@/components/common';
import {useRouter} from '@/hooks';
import {useParams, useSearchParams} from 'next/navigation';
import {useReOrderMutation} from '@/services';
import {showErrorToast, showSuccessToast} from '@/common';

const OrderCancellation = () => {
  const t = useTranslations();
  const router = useRouter();
  const searchParams = useSearchParams();
  const {orderNumber} = useParams();

  const [reOrder, {isLoading: isReOrdering}] = useReOrderMutation();

  const handleReOrder = async () => {
    const response = await reOrder({orderNumber});
    if (!response.error) {
      showSuccessToast(t('you_have_re_ordered_successfully'));
    } else {
      showErrorToast(response.error.error);
    }
  };

  return (
    <div className="xl:mx-[4rem]">
      <div className="flex items-center justify-between p-8 pb-[8rem]">
        <div>
          <p className="mb-4 font-main text-[14px] font-bold text-persianIndigo xl:text-[1vw]">
            {t('order_confirmation')}
          </p>
          <p className="mb-4 font-main text-[16px] font-medium text-persianIndigo xl:text-[1.5vw]">
            {t('cancellation_successful')}
          </p>
          <p className="mb-6 font-secondary font-normal text-persianIndigo xl:text-[1vw]">
            {t('your_order_has_been_successfully_cancelled_due_to')} "
            {searchParams?.get('reason')}"
          </p>
          <p className="mb-6 font-secondary text-[14px] font-normal text-persianIndigo xl:text-[1.2vw]">
            {t('would_you_like_to_reorder')}
          </p>
          <CustomButton
            value={t('re_order')}
            btnStyles="w-full mt-5"
            onClickHandling={handleReOrder}
            isLoading={isReOrdering}
          />
          <CustomButton
            value={t('go_to_home')}
            onClickHandling={() => router.replace('/')}
            btnStyles="w-full mt-5 font-secondary text-[17px] text-persianIndigo font-normal underline"
            btnType="tertiary"
          />
        </div>
        <div className="hidden xl:block">
          <Image src={product2} className="self-end" alt="rating" />
        </div>
      </div>
    </div>
  );
};

export default OrderCancellation;
