import {visa} from '@/assets';
import Image from 'next/image';

const PaymentCard = ({formik, t}) => {
  function formatNumberWithSpaces(number) {
    return number.toString().replace(/\B(?=(\d{4})+(?!\d))/g, ' ');
  }

  return (
    <div className="relative h-[8rem] w-[15rem] rounded-lg bg-gradient-to-r from-crayola to-blueBell opacity-50">
      <Image
        src={visa}
        width={50}
        height={50}
        alt="visa logo"
        className="absolute right-2 top-2"
      />
      <p className="absolute left-2 top-[45%] font-secondary text-[16px] font-extrabold text-white">
        {formik.values.cardNumber
          ? formatNumberWithSpaces(formik.values.cardNumber)
          : '0000 0000 0000 0000'}
      </p>
      <div className="absolute left-0 top-2/3 mx-[0.5rem] flex w-[12rem] items-center justify-between">
        <p className="font-secondary text-[10px] font-normal text-white">
          {t('name')}
        </p>
        <p className="font-secondary text-[10px] font-normal text-white">
          {t('exp_date')}
        </p>
      </div>
      <div className="absolute left-0 top-[80%] mx-[0.5rem] flex w-[11.5rem] items-center justify-between">
        <p className="font-secondary text-[13px] font-extrabold text-white">
          {formik.values.name ? formik.values.name : t('full_name')}
        </p>
        <p className="font-secondary text-[13px] font-extrabold text-white">
          {formik.values.expiryDate ? formik.values.expiryDate : '--/--'}
        </p>
      </div>
    </div>
  );
};

export default PaymentCard;
