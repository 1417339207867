'use client';

import {Modal, ModalContent, ModalHeader, ModalBody} from '@heroui/modal';
import {AdvancedMarker, APIProvider, Map, Pin} from '@vis.gl/react-google-maps';
import {useMemo, useState} from 'react';
import Image from 'next/image';
import {minus, plus} from '@/assets';
import {useSelector} from 'react-redux';
import {getCurrentUserCoords, getNearestStoreData} from '@/selectors';
import {CustomButton} from '../../common';
import {useTranslations} from 'next-intl';

const PickupLocation = ({isOpen, setIsOpen}) => {
  const t = useTranslations();
  const userCoords = useSelector(getCurrentUserCoords);
  const store = useSelector(getNearestStoreData);

  const [zoom, setZoom] = useState(10);

  const handleZoomIn = () => {
    setZoom(prevZoom => prevZoom + 1);
  };

  const handleZoomOut = () => {
    setZoom(prevZoom => prevZoom - 1);
  };

  const CustomZoomControls = ({onZoomIn, onZoomOut}) => {
    return (
      <div className="absolute bottom-[12px] right-[18px] z-[1]">
        <button onClick={onZoomIn} className="mr-[10px]">
          <Image className="h-[20px] w-[20px]" src={plus} alt="plus" />
        </button>
        <button onClick={onZoomOut}>
          <Image src={minus} className="h-[20px] w-[20px]" alt="minus" />
        </button>
      </div>
    );
  };

  const userLocation = useMemo(() => {
    return userCoords?.userLat && userCoords?.userLng
      ? {lat: userCoords.userLat, lng: userCoords.userLng}
      : {lat: 24.7136, lng: 46.6753};
  }, [userCoords]);

  return (
    <Modal
      isOpen={isOpen}
      size="2xl"
      onClose={() => setIsOpen(false)}
      className="mx-[3rem] mt-[1.5rem] h-[42.375rem] w-[19.625rem] p-4 sm:h-[60dvh] sm:w-full"
      classNames={{
        closeButton: 'hidden',
        wrapper: 'items-start sm:items-center',
      }}>
      <ModalContent>
        <ModalHeader className="mb-4 flex items-center justify-between !p-0 text-center font-main text-[14px] font-medium text-persianIndigo sm:text-[16px] xl:text-[20px]">
          <span className="w-full text-center sm:ml-[3.75rem]">
            {t('your_pickup_store_location')}
          </span>
          <CustomButton
            btnType="tertiary"
            value={'X'}
            btnStyles="text-[22px] text-persianIndigo !hover:!bg-none !p-0 hidden sm:block"
            onClickHandling={() => setIsOpen(false)}
          />
        </ModalHeader>
        <ModalBody className="h-full !p-0">
          <APIProvider
            apiKey={`${process.env.NEXT_PUBLIC_REACT_APP_MAPS_API_KEY}`}>
            <Map
              mapId="DEMO_MAP_ID"
              className="h-[90%] w-full"
              scaleControl={false}
              disableDefaultUI={true}
              zoomControl={false}
              zoom={zoom}
              center={{
                lat: store?.location?.latitude,
                lng: store?.location?.longitude,
              }}>
              <div>
                <CustomZoomControls
                  onZoomIn={handleZoomIn}
                  onZoomOut={handleZoomOut}
                />
              </div>
              <AdvancedMarker position={userLocation}>
                <Pin
                  background={'#0000FF'}
                  borderColor={'#0000FF'}
                  glyphColor={'#000056'}
                />
              </AdvancedMarker>
              <AdvancedMarker
                position={{
                  lat: store?.location?.latitude,
                  lng: store?.location?.longitude,
                }}
              />
            </Map>
          </APIProvider>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default PickupLocation;
