import {convertEnglishNumbersToArabic} from '@/common';
import {useLocale} from 'next-intl';
import React, {Fragment} from 'react';
import {FaCheck} from 'react-icons/fa6';

const OrderProgress = ({
  currentStep,
  t,
  formattedDate,
  toTime,
  scheduleTime,
  timeSlot,
  shoppingMethodId,
}) => {
  const locale = useLocale();
  const steps = [
    {id: 1, name: 'ordered', description: formattedDate},
    {
      id: 2,
      name: `${shoppingMethodId !== 1 ? 'ready_to_pick2' : 'ready_to_pick1'}`,
      description: '',
    },
    {
      id: 3,
      name: Number(shoppingMethodId) === 1 ? 'delivered' : 'picked',
      description: !scheduleTime ? (
        `${t('at')} ${convertEnglishNumbersToArabic(toTime, locale)}`
      ) : (
        <>
          {t('on')} {convertEnglishNumbersToArabic(scheduleTime, locale)},{' '}
          <br />
          {t('between')} {convertEnglishNumbersToArabic(timeSlot, locale)}
        </>
      ),
    },
  ];

  return (
    <>
      <div className="mt-4 flex w-full">
        {steps.map((step, index) => (
          <div key={step.id} className="w-[33%] sm:w-[25%] xl:w-[33%]">
            <div
              className={`${step.id === 3 ? 'justify-self-start' : 'justify-center'} flex items-center`}>
              <div
                className={`flex h-10 w-10 items-center justify-center rounded-full border-2 ${
                  +currentStep > step.id
                    ? 'border-thistle bg-thistle text-white'
                    : 'border-thistle text-thistle'
                }`}>
                {+currentStep > step.id ? <FaCheck size={25} /> : ''}
              </div>
              {index < steps.length - 1 && (
                <div className="h-[0.125rem] flex-1 bg-thistle"></div>
              )}
            </div>
            <div key={index} className="flex flex-col">
              <div className="font-secondary text-[12px] font-bold text-persianIndigo xl:text-[1vw]">
                {t(step.name)}
              </div>
              <div className="font-secondary text-[10px] font-normal text-persianIndigo xl:text-[0.8vw]">
                {step.description}
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default OrderProgress;
