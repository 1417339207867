'use client';

import React, {useState} from 'react';
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
} from '@heroui/react';
import {rules, showErrorToast} from '@/common';
import * as Yup from 'yup';
import {useDispatch, useSelector} from 'react-redux';
import {getIsForgetPasswordModalOpen} from '@/selectors';
import {
  closeForgetPassword,
  openForgetPassword,
  openNewPasswordUpdateModal,
  setUserPhone,
} from '@/slices';
import {useFormik} from 'formik';
import {useLocale, useTranslations} from 'next-intl';
import {useRequestUserOtpMutation, useVerifyUserOtpMutation} from '@/services';
import OTPInput from 'react-otp-input';
import {CustomButton} from '../common';
import Image from 'next/image';
import {phone_label} from '@/assets';

const ForgetPasswordModal = () => {
  const isForgetPasswordModalOpen = useSelector(getIsForgetPasswordModalOpen);
  const dispatch = useDispatch();
  const t = useTranslations();
  const locale = useLocale();
  const [isPhoneApproved, setIsPhoneApproved] = useState(false);
  const [otp, setOtp] = useState('');
  const [requestUserOtp] = useRequestUserOtpMutation();
  const [verifyUserOtp] = useVerifyUserOtpMutation();

  const validationSchema = Yup.object({
    phoneNumber: rules('loginPhoneNumber', null, t),
  });

  const formik = useFormik({
    initialValues: {
      phoneNumber: '',
    },
    validationSchema,
  });

  const onOpeningForgetPasswordModal = () => {
    dispatch(openForgetPassword());
  };
  const onClosingForgetPassword = () => {
    setIsPhoneApproved(false);
    formik.resetForm();
    dispatch(closeForgetPassword());
  };

  const onCheckingPhoneNumber = async () => {
    const response = await requestUserOtp({
      username: `966${formik.values.phoneNumber}`,
    });
    if (!response.error) {
      setIsPhoneApproved(true);
      dispatch(setUserPhone(formik.values.phoneNumber));
    } else {
      showErrorToast(response?.error.error);
    }
  };

  const onHandlingResendingOTP = async () => {
    const response = await requestUserOtp({
      username: `966${formik.values.phoneNumber}`,
    });
    if (response?.error) {
      showErrorToast(response.error.error);
    }
  };
  const onHandlingForgetPasswordOTP = async () => {
    const response = await verifyUserOtp({
      username: `966${formik.values.phoneNumber}`,
      otp_code: otp,
    });
    if (response?.error) {
      showErrorToast(response.error.error);
    } else {
      dispatch(closeForgetPassword());
      dispatch(openNewPasswordUpdateModal());
    }
  };

  return (
    <div>
      <Modal
        isOpen={isForgetPasswordModalOpen}
        size="2xl"
        className="mx-4 sm:w-[485px] sm:px-[10px] sm:py-[25px] xl:h-auto xl:w-[500px] xl:px-[30px] xl:py-[35px]"
        onOpenChange={onOpeningForgetPasswordModal}
        onClose={onClosingForgetPassword}
        closeButton={
          <CustomButton
            btnType="tertiary"
            value={'X'}
            onClickHandling={onClosingForgetPassword}
          />
        }
        classNames={{
          closeButton:
            '!text-[22px] !text-persianIndigo hover:!bg-thistle font-main !p-0 !rounded-md mt-4 hidden sm:block',
          wrapper: 'items-center',
        }}>
        <ModalContent>
          {onClose => (
            <>
              <ModalHeader className="justify-center">
                <span className="border-b-4 border-persianIndigo pb-[10px] text-center font-main font-medium text-persianIndigo sm:text-[18px] xl:text-[20px]">
                  {t('forget_password')}
                </span>
              </ModalHeader>
              <form>
                <ModalBody>
                  <div className="mb-3 font-secondary">
                    <Input
                      type="text"
                      variant="underlined"
                      name="phoneNumber"
                      placeholder={t('enter_your_phone_number')}
                      value={formik.values.phoneNumber}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      startContent={
                        <div
                          className={`${locale === 'ar' ? 'ml-2' : 'mr-2'} flex w-[6.5rem] items-center gap-2 md:w-[6.3rem]`}>
                          <Image
                            width={35}
                            height={10}
                            src={phone_label}
                            alt="phone label"
                          />
                          <p className="text-[14px] text-persianIndigo">+966</p>
                        </div>
                      }
                      className="w-full text-[16px]"
                      classNames={{
                        inputWrapper:
                          '!border-thistle hover:border-thistle focus:border-thistle focus:border-[5px] !outline-0 after:bg-persianIndigo',
                        input: '!text-persianIndigo placeholder:text-thistle',
                        label: '!text-thistle',
                      }}
                    />
                    {formik.touched.phoneNumber && formik.errors.phoneNumber ? (
                      <div
                        className={`mt-2 font-secondary text-[12px] text-red-500`}>
                        {formik.errors.phoneNumber}
                      </div>
                    ) : null}
                  </div>
                  {isPhoneApproved && (
                    <>
                      <OTPInput
                        value={otp}
                        onChange={setOtp}
                        numInputs={6}
                        containerStyle="w-full justify-center gap-4 mt-[40px] mb-[20px]"
                        renderSeparator={null}
                        renderInput={props => (
                          <input
                            {...props}
                            className="!sm:h-[73px] !sm:w-[52px] !h-[50px] !w-[30px] rounded-[17px] bg-lightPurple font-secondary font-bold text-persianIndigo outline-white"
                          />
                        )}
                      />
                      <p className="sm:text[14px] mb-[40px] mr-[9px] text-center font-secondary text-persianIndigo xl:text-[16px]">
                        {t('didnt_recieve_otp')}
                        <span
                          className="cursor-pointer underline"
                          onClick={onHandlingResendingOTP}>
                          {t('send_code_again')}
                        </span>
                      </p>
                    </>
                  )}
                </ModalBody>
                <ModalFooter className="justify-center pb-4 sm:pb-0">
                  <CustomButton
                    onClickHandling={
                      isPhoneApproved
                        ? onHandlingForgetPasswordOTP
                        : onCheckingPhoneNumber
                    }
                    btnStyles={'h-[2.813rem] w-[16.625rem]'}
                    btnActionType={'button'}
                    value={t('continue')}
                    isDisabled={!formik.isValid || !formik.dirty}
                  />
                </ModalFooter>
              </form>
            </>
          )}
        </ModalContent>
      </Modal>
    </div>
  );
};

export default ForgetPasswordModal;
