'use client';

import {
  getCurrentUserCoords,
  getDeliveryMethodId,
  getNearestStoreData,
  getOrderNumber,
} from '@/selectors';
import {useLocale, useTranslations} from 'next-intl';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {CancelOrderModal, OrderProgress, RatingModal} from '.';
import {useDispatch, useSelector} from 'react-redux';
import moment from 'moment';
import localization from 'moment/locale/ar';
import {
  useGetOrderStatusQuery,
  useLazyGetClosestBranchesQuery,
} from '@/services';
import {CustomButton} from '@/components/common';
import {
  getIsGuestPaymentStep,
  getIsAddingNewCard,
} from '@/selectors/deliverySelector';
import {getLocalized12HourFormat} from '@/common';
import {
  setGuestPaymentId,
  setIsApplePayment,
  setIsFinishingPayment,
} from '@/slices';
import Link from 'next/link';

const ExperienceRating = () => {
  const t = useTranslations();
  const dispatch = useDispatch();
  const locale = useLocale();

  const orderNumber = useSelector(getOrderNumber);
  const deliveryMethodId = useSelector(getDeliveryMethodId);
  const isGuestPaymentStep = useSelector(getIsGuestPaymentStep);
  const isAddingNewCard = useSelector(getIsAddingNewCard);
  const userCoords = useSelector(getCurrentUserCoords);
  const store = useSelector(getNearestStoreData);

  const [getCurrentBranch] = useLazyGetClosestBranchesQuery();

  const [deliveryTime, setDeliveryTime] = useState(null);
  const [isRatingModalOpen, setIsRatingModalOpen] = useState(false);
  const [isCancellationModalOpen, setIsCancellationModalOpen] = useState(false);

  useEffect(() => {
    dispatch(setGuestPaymentId(null));
    dispatch(setIsApplePayment(false));
    dispatch(setIsFinishingPayment(false));
    moment.locale(locale, localization);
  }, []);

  useEffect(() => {
    const fetchDeliveryTime = async () => {
      const currentBranch = await getCurrentBranch({
        body: {
          latitude: userCoords?.userLat,
          longitude: userCoords?.userLng,
          shopping_method_id: deliveryMethodId,
          locale,
        },
      });
      setDeliveryTime(currentBranch?.data?.data?.attributes?.delivery_time);
    };
    if (deliveryMethodId === 1 && userCoords?.userLat && userCoords?.userLng) {
      fetchDeliveryTime();
    }
  }, [orderNumber, deliveryMethodId, userCoords]);

  const {data: orderStatus, error: getOrderStatusError} =
    useGetOrderStatusQuery({orderNumber, locale}, {pollingInterval: 15000});

  const preparationTimes = useMemo(
    () =>
      orderStatus?.included
        ?.filter(item => item.type === 'product')
        .map(item => item.attributes.preparation_time)
        .filter(time => time !== null)
        .sort((a, b) => b - a),
    [orderStatus],
  );

  const maxPreparationTime = useMemo(
    () => preparationTimes?.[0] || 0,
    [preparationTimes],
  );

  const handleRatingClick = () => {
    setIsRatingModalOpen(true);
  };

  const handleCancelationClick = () => {
    setIsCancellationModalOpen(true);
  };

  const formattedDate = useMemo(() => {
    if (orderStatus?.data?.attributes?.completed_at) {
      return moment(orderStatus.data.attributes.completed_at)
        .local()
        .format(
          `${locale === 'ar' ? '[في]' : '[On]'} MMM D ${
            locale === 'ar' ? '[الساعة]' : '[at]'
          } h:mm A`,
        );
    }
    return '';
  }, [orderStatus?.data?.attributes?.completed_at, locale]);

  const instantShippingTime = useMemo(() => {
    return orderNumber && deliveryMethodId === 1
      ? deliveryTime + maxPreparationTime
      : maxPreparationTime;
  }, [orderNumber, deliveryTime, maxPreparationTime]);

  const toTime = moment(orderStatus?.data?.attributes?.completed_at)
    .add(instantShippingTime, 'hours')
    .format('h:mm A');

  if (getOrderStatusError) {
    throw new Error('Failed to get order status.');
  }

  const isOrderCancellationDisabled = useMemo(
    () =>
      orderStatus?.data?.attributes?.shipment_state === 'canceled' ||
      orderStatus?.data?.attributes?.shipment_state !== 'pending',
    [orderStatus, isAddingNewCard],
  );

  const currentStep = useMemo(() => {
    const isShoppingMethodNotDelivery = deliveryMethodId !== 1;

    if (
      orderStatus?.data?.attributes?.shipment_state ===
      (isShoppingMethodNotDelivery ? 'picked' : 'delivered')
    ) {
      return 4;
    }

    const isReadyToPick =
      orderStatus?.data?.attributes?.shipment_state === 'ready_to_pick';
    const isPickedOrOnTheWay =
      orderStatus?.data?.attributes?.shipment_state === 'picked' ||
      orderStatus?.data?.attributes?.shipment_state === 'on_the_way';

    if (
      isShoppingMethodNotDelivery
        ? isReadyToPick
        : isReadyToPick || isPickedOrOnTheWay
    ) {
      return 3;
    }

    return 2;
  }, [orderStatus]);

  const handleMapLinkClick = useCallback(
    event => {
      event.preventDefault();
      const mapsUrl = `https://www.google.com/maps/dir/?api=1&origin=${userCoords?.userLat},${userCoords?.userLng}&destination=${store?.location?.latitude},${store?.location?.longitude}&z=10`;

      window.open(mapsUrl, '_blank');
    },
    [userCoords, store],
  );

  return (
    <>
      {orderNumber ? (
        <div className="xl:mx-[4rem]">
          <div className="flex items-center justify-between p-8 pb-[8rem]">
            <div className="w-full xl:w-[47.5%]">
              <p className="mb-2 font-main text-[14px] font-bold text-persianIndigo xl:text-[0.9vw]">
                {t('order_confirmation')}
              </p>
              <p className="mb-2 font-main text-[20px] font-medium text-persianIndigo xl:text-[2vw]">
                {t('thank_you')}!
              </p>
              <p className="mb-2 font-main text-[12px] font-medium text-persianIndigo xl:text-[1vw]">
                {t('great_news')}:{' '}
                {t('your_order_has_been_received', {orderNumber})}
              </p>
              <p className="mb-2 font-secondary text-[12px] font-normal text-persianIndigo xl:text-[1.2vw]">
                {t(
                  'we_send_tracking_info_via_WhatsApp_SMS_when_your_order_ships',
                )}
              </p>
              <p className="mt-2 font-secondary text-[12px] font-normal text-persianIndigo xl:text-[1vw]">
                {t('we_ll_start_working_on_it_right_away')}
              </p>
              <OrderProgress
                currentStep={currentStep}
                t={t}
                formattedDate={formattedDate}
                toTime={toTime}
                scheduleTime={
                  orderStatus?.data?.attributes?.schedule_time
                    ? moment(
                        orderStatus?.data?.attributes?.schedule_time,
                      ).format('YYYY-MM-DD')
                    : null
                }
                timeSlot={
                  orderStatus?.data?.relationships?.delivery_slot?.data
                    ? `${getLocalized12HourFormat(orderStatus?.included?.[0]?.attributes?.start_time, locale)} - ${getLocalized12HourFormat(orderStatus?.included?.[0]?.attributes?.end_time, locale)}`
                    : null
                }
                shoppingMethodId={deliveryMethodId}
              />
              {!isGuestPaymentStep && !isAddingNewCard && (
                <p className="mt-4 font-secondary text-[14px] font-bold italic text-persianIndigo xl:text-[1vw]">
                  {t('we_would_love_to_hear_about_your_ordering_experience')}
                </p>
              )}
              {deliveryMethodId !== 1 && (
                <Link target="_blank" href="#" onClick={handleMapLinkClick}>
                  <p className="mt-4 cursor-pointer font-secondary text-[12px] font-bold italic text-persianIndigo underline xl:text-[0.8vw]">
                    {t('show_the_pickup_store_location_on_google_map')}
                  </p>
                </Link>
              )}
              <div className="flex flex-col">
                {!isGuestPaymentStep && !isAddingNewCard && (
                  <CustomButton
                    value={t('rate_your_shopping_experience')}
                    onClickHandling={handleRatingClick}
                    btnStyles="w-full sm:w-[50%] xl:w-full mt-4"
                  />
                )}
                {!isOrderCancellationDisabled && (
                  <CustomButton
                    value={t('cancel_order')}
                    onClickHandling={handleCancelationClick}
                    btnStyles="w-full sm:w-[50%] xl:w-full mt-2 font-secondary text-persianIndigo font-normal underline"
                    btnType="tertiary"
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="flex h-[75vh] flex-col items-center justify-center">
          <p className="mt-2 w-[50%] text-center font-main text-lg">
            {t('you_did_not_order_yet_place_order_first')}
          </p>
        </div>
      )}
      {!isGuestPaymentStep && !isAddingNewCard && (
        <RatingModal
          isRatingModalOpen={isRatingModalOpen}
          setIsRatingModalOpen={setIsRatingModalOpen}
        />
      )}
      <CancelOrderModal
        isOrderCancellationModalOpen={isCancellationModalOpen}
        setIsOrderCancellationModalOpen={setIsCancellationModalOpen}
        order={orderStatus?.data?.attributes}
      />
    </>
  );
};

export default ExperienceRating;
