'use client';

import {getCart, getIsOTPModalOpen, getToken} from '@/selectors';
import {Button, Input} from '@heroui/react';
import {useLocale, useTranslations} from 'next-intl';
import {useEffect, useMemo, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {MdKeyboardArrowDown} from 'react-icons/md';
import {Calendar} from '@heroui/react';
import moment from 'moment';
import {parseDate} from '@internationalized/date';
import {today, getLocalTimeZone} from '@internationalized/date';
import {
  useGetUserWithAddressesQuery,
  useLazyGetAllDeliverySlotsQuery,
  useLazyGetOrderPreparationAbilityQuery,
  useRequestUserOtpMutation,
  useUpdateCheckoutMutation,
  useVerifyUserOtpMutation,
} from '@/services';
import {
  commonDisableCachingParameters,
  convertEnglishNumbersToArabic,
  getLocalized12HourFormat,
  showErrorToast,
} from '@/common';
import {useRouter} from '@/hooks';
import {useSearchParams} from 'next/navigation';
import {CustomButton} from '@/components/common';
import {openOtpModal} from '@/slices';
import {OtpModal} from '@/components/Auth';

const ShippingTime = () => {
  const t = useTranslations();
  const locale = useLocale();
  const router = useRouter();
  const searchParams = useSearchParams();
  const now = moment().locale('en');
  const formattedDate = now.format('YYYY-MM-DD');
  moment.locale('en');

  const [currentDeliveryType, setCurrentDeliveryType] = useState(1);
  const [showCalendar, setShowCalendar] = useState(false);
  const [showTimeSlots, setShowTimeSlots] = useState(false);
  const [value, setValue] = useState(parseDate(formattedDate));
  const [inputDate, setInputDate] = useState('');
  const [selectedSlotId, setSelectedSlotId] = useState(null);
  const [startSlot, setStartSlot] = useState(null);
  const [endSlot, setEndSlot] = useState(null);
  const token = useSelector(getToken);
  const [preparationError, setPreparationError] = useState(null);
  const isOtpModalOpen = useSelector(getIsOTPModalOpen);
  const dispatch = useDispatch();
  const isInstant = useMemo(
    () => currentDeliveryType === 1,
    [currentDeliveryType],
  );

  const [
    getDeliverySlots,
    {data: deliverySlots, error: getDeliverySlotsError},
  ] = useLazyGetAllDeliverySlotsQuery();

  const [updateShippingTime, {isLoading: isUpdatingShippingTime}] =
    useUpdateCheckoutMutation();
  const [requestUserOtp] = useRequestUserOtpMutation();
  const [verifyUserOtp] = useVerifyUserOtpMutation();
  const [checkForAbilityToPay] = useLazyGetOrderPreparationAbilityQuery();

  const {data: userData} = useGetUserWithAddressesQuery(
    {locale},
    {
      skip: !token,
      ...commonDisableCachingParameters,
    },
  );
  const handleDateChange = async newValue => {
    setValue(newValue);

    const date = moment(
      `${newValue.year}-${newValue.month}-${newValue.day}`,
      'YYYY-M-D',
    ).locale('en');
    const formatter = new Intl.DateTimeFormat(locale, {
      month: 'long',
      weekday: 'long',
      day: 'numeric',
    });
    const dateInput = formatter.format(date);

    const scheduleDate = moment(
      `${newValue.year}-${newValue.month}-${newValue.day}`,
      'YYYY-M-D',
    )
      .locale('en')
      .format('MMM Do YYYY');

    const response = await updateShippingTime({
      order: {
        schedule_time: isInstant ? null : scheduleDate,
      },
    });

    await getDeliverySlots({
      order_id: response?.data?.data?.attributes?.number,
      working_day_id:
        response?.data?.data?.relationships?.working_day?.data?.id,
    });

    const formattedDate =
      locale === 'ar'
        ? dateInput.replace(/\d/g, d => '٠١٢٣٤٥٦٧٨٩'[d])
        : dateInput;

    setInputDate(formattedDate);
    setShowCalendar(false);
    setSelectedSlotId(null);
    setShowTimeSlots(false);
  };

  const cartItems = useSelector(getCart);

  const instantShippingTime = useMemo(() => {
    return cartItems
      ? cartItems.branchDeliveryTime + cartItems.maxPreparationTime
      : 3;
  }, [cartItems]);

  const readyToProceed = useMemo(
    () => (isInstant ? false : !(inputDate && selectedSlotId)),
    [inputDate, selectedSlotId, isInstant],
  );

  const handleSelectSlot = item => {
    setSelectedSlotId(item.id);
    setStartSlot(getLocalized12HourFormat(item.attributes.start_time, locale));
    setEndSlot(getLocalized12HourFormat(item.attributes.end_time, locale));
    setShowTimeSlots(false);
  };

  const handleUpdateShippingTime = async id => {
    const response = await updateShippingTime({
      order: {
        delivery_slot_id: isInstant ? '' : id,
        ...(isInstant && {schedule_time: ''}),
      },
    });
    if (response?.error) {
      showErrorToast(response.error.error);
    } else {
      const response = await checkForAbilityToPay();
      if (response?.error) {
        const data = response?.error?.error;
        setPreparationError(
          t(`preparation_error`, {
            productName: data?.product_name,
            productPrepHours: data?.preparation_time,
          }),
        );
      } else {
        onHandlingUnVerifiedUser();
      }
    }
  };

  const onHandlingUnVerifiedUser = async () => {
    if (!userData?.user?.isUserVerified && token) {
      await requestUserOtp({username: userData?.user.phoneNumber});
      dispatch(openOtpModal());
    } else {
      onNavigatingToPaymentStep();
    }
  };

  const onNavigatingToPaymentStep = () => {
    router.push('/checkout/4');
  };

  const onSwitchingToInstant = () => {
    setCurrentDeliveryType(1);
    setPreparationError(null);
  };
  const onSwitchingToScheduled = () => {
    setCurrentDeliveryType(2);
    setPreparationError(null);
  };
  const onTogglingCalander = () => {
    setShowCalendar(!showCalendar);
    setPreparationError(null);
  };
  const onToggleTimeSlots = () => {
    setShowTimeSlots(!showTimeSlots);
    setPreparationError(null);
  };

  useEffect(() => {
    if (searchParams.get('token')) {
      onNavigatingToPaymentStep();
    }
  }, []);

  const isToShowInstantDeliveryDescription = useMemo(
    () => isInstant && Number(instantShippingTime) > 0,
    [currentDeliveryType, instantShippingTime],
  );

  const formatShippingTime = time => {
    if (time < 1) {
      return `${Math.round(time * 60)} ${t('minutes')}`;
    } else if (time === 1) {
      return `${time} ${t('hourr')}`;
    }
    return `${time} ${t('hourrs')}`;
  };

  if (getDeliverySlotsError) {
    throw new Error('Failed to load delivery slots.');
  }

  return (
    <div className="py-5">
      <h2 className="font-main text-[16px] font-medium text-persianIndigo xl:text-[1.1vw]">
        {t('shipping_time')}
      </h2>
      {isToShowInstantDeliveryDescription ? (
        <p className="mt-2 font-secondary text-[14px] font-normal text-persianIndigo xl:text-[1vw]">
          <span
            dangerouslySetInnerHTML={{
              __html: t('please_note_your_order_will_be_delivered_within', {
                instantShippingTime: formatShippingTime(instantShippingTime),
              }).replace(
                formatShippingTime(instantShippingTime),
                convertEnglishNumbersToArabic(
                  formatShippingTime(instantShippingTime),
                  locale,
                ),
              ),
            }}
          />
        </p>
      ) : null}
      <div className="my-6 flex w-full">
        <div className="w-[50%]">
          <Button
            radius="none"
            onPress={onSwitchingToInstant}
            className={`${isInstant ? 'bg-persianIndigo' : 'bg-thistle'} ${locale === 'ar' ? 'rounded-r-[5px]' : 'rounded-l-[5px]'} h-[60px] w-full font-main text-[20px] text-white`}>
            {t('instant')}
          </Button>
        </div>
        <div className="w-[50%]">
          <Button
            radius="none"
            onPress={onSwitchingToScheduled}
            className={`${!isInstant ? 'bg-persianIndigo' : 'bg-thistle'} ${locale === 'ar' ? 'rounded-l-[5px]' : 'rounded-r-[5px]'} h-[60px] w-full font-main text-[20px] text-white`}>
            {t('scheduled')}
          </Button>
        </div>
      </div>

      {!isInstant ? (
        <>
          <div className="my-4">
            <Input
              type="text"
              className="mb-4"
              classNames={{
                innerWrapper: 'cursor-pointer',
                inputWrapper:
                  'border-thistle after:bg-transparent transition-colors data-[hover=true]:border-thistle group-data-[focus=true]:border-[#D6BDD0]',
                input:
                  '!text-persianIndigo cursor-pointer text-[16px] xl:text-[14px]  placeholder:text-thistle placeholder:text-[16px] xl:placeholder:text-[14px]',
              }}
              variant="underlined"
              placeholder={t('select_date')}
              endContent={
                <MdKeyboardArrowDown
                  className="text-[30px] text-persianIndigo xl:text-[20px]"
                  onClick={onTogglingCalander}
                />
              }
              readOnly
              value={inputDate}
              onClick={onTogglingCalander}
            />
            {showCalendar ? (
              <Calendar
                value={value}
                onChange={handleDateChange}
                defaultValue={today(getLocalTimeZone())}
                minValue={today(getLocalTimeZone())}
                calendarWidth="100%"
                weekdayStyle="short"
                color="secondary"
                classNames={{
                  cell: 'p-[0.5rem]',
                  gridHeaderCell: 'p-[1.5rem]',
                  content: 'bg-antiFlashWhite',
                  headerWrapper: 'bg-antiFlashWhite',
                  gridHeader: 'bg-antiFlashWhite',
                }}
              />
            ) : null}
            <Input
              classNames={{
                innerWrapper: 'cursor-pointer',
                inputWrapper:
                  'border-thistle after:bg-transparent transition-colors data-[hover=true]:border-thistle group-data-[focus=true]:border-[#D6BDD0]',
                input:
                  '!text-persianIndigo cursor-pointer text-[16px] xl:text-[14px] placeholder:text-thistle placeholder:text-[16px] xl:placeholder:text-[14px]',
              }}
              variant="underlined"
              placeholder={t('select_time')}
              endContent={
                <MdKeyboardArrowDown
                  className="text-[30px] text-persianIndigo xl:text-[20px]"
                  onClick={onToggleTimeSlots}
                />
              }
              readOnly
              value={
                selectedSlotId
                  ? `${convertEnglishNumbersToArabic(startSlot, locale)} - ${convertEnglishNumbersToArabic(endSlot, locale)}`
                  : ''
              }
              onClick={onToggleTimeSlots}
            />
            {showTimeSlots &&
              (deliverySlots?.data?.length > 0 ? (
                <div className="mt-4">
                  {deliverySlots?.data?.map((slot, index) => {
                    const isAvailable = slot?.attributes?.available;

                    return (
                      <div
                        key={index}
                        className={`border-b-1 border-thistle bg-antiFlashWhite px-[1rem] py-[1rem] ${
                          isAvailable
                            ? 'cursor-pointer text-persianIndigo'
                            : 'cursor-not-allowed text-silverChalice'
                        }`}
                        onClick={() => isAvailable && handleSelectSlot(slot)}>
                        {convertEnglishNumbersToArabic(
                          getLocalized12HourFormat(
                            slot.attributes.start_time,
                            locale,
                          ),
                          locale,
                        )}{' '}
                        -{' '}
                        {convertEnglishNumbersToArabic(
                          getLocalized12HourFormat(
                            slot.attributes.end_time,
                            locale,
                          ),
                          locale,
                        )}
                      </div>
                    );
                  })}
                </div>
              ) : (
                <div className="mt-4 cursor-pointer border-b-1 border-thistle bg-antiFlashWhite px-[1rem] py-[1rem] text-persianIndigo">
                  {t('no_available_time_slots')}
                </div>
              ))}
          </div>
        </>
      ) : null}
      {preparationError && (
        <div className="my-2 font-secondary text-[12px] text-pink">
          <p className="font-semiBold text-[14px]">
            {t('unable_to_place_order_at_the_moment')}
          </p>
          <p>{preparationError}</p>
        </div>
      )}

      <div>
        <CustomButton
          btnStyles="w-full h-[52px]"
          isDisabled={readyToProceed}
          value={t('proceed_to_payment')}
          isLoading={isUpdatingShippingTime}
          onClickHandling={() => handleUpdateShippingTime(selectedSlotId)}
        />
      </div>
      <OtpModal
        isOpen={isOtpModalOpen}
        userPhoneNumber={userData?.user.phoneNumber}
        onRequestOtp={() =>
          requestUserOtp({username: userData?.user.phoneNumber})
        }
        onVerifyOtp={otp =>
          verifyUserOtp({username: userData?.user.phoneNumber, otp_code: otp})
        }
        onVerificationSuccess={onNavigatingToPaymentStep}
      />
    </div>
  );
};

export default ShippingTime;
