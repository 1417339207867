'use client';
import React, {useState} from 'react';
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from '@heroui/react';
import {useDispatch} from 'react-redux';
import {closeGeneralOtpModal, closeOtpModal} from '@/slices';
import {useTranslations} from 'next-intl';
import OTPInput from 'react-otp-input';
import {showErrorToast} from '@/common';
import {CustomButton} from '../common';

const OtpModal = ({
  isOpen,
  onVerificationSuccess,
  onVerificationFailure,
  onRequestOtp,
  onVerifyOtp,
}) => {
  const dispatch = useDispatch();
  const t = useTranslations();
  const [otp, setOtp] = useState('');
  const [loading, setLoading] = useState(false);

  const handleVerification = async () => {
    setLoading(true);
    try {
      const response = await onVerifyOtp(otp);
      if (response?.error) {
        showErrorToast(response.error.error);
        onVerificationFailure?.(response.error);
      } else {
        onVerificationSuccess?.(response);
      }
    } catch (error) {
      showErrorToast('An unexpected error occurred.');
      onVerificationFailure?.(error);
    } finally {
      setLoading(false);
    }
  };

  const handleResendOtp = async () => {
    try {
      const response = await onRequestOtp();
      if (response?.error) {
        showErrorToast(response.error.error);
      }
    } catch (error) {
      showErrorToast('Failed to resend OTP.');
    }
  };
  const closingOTPModal = () => {
    dispatch(closeOtpModal());
    dispatch(closeGeneralOtpModal());
  };

  return (
    <Modal
      isOpen={isOpen}
      size="2xl"
      className="mx-4 sm:h-[410px] sm:w-[485px] sm:px-[10px] sm:py-[25px] xl:h-auto xl:w-auto xl:px-[30px] xl:py-[35px]"
      onOpenChange={closingOTPModal}
      onClose={closingOTPModal}
      classNames={{
        closeButton: 'hidden',
        wrapper: 'items-center',
      }}>
      <ModalContent>
        {() => (
          <>
            <ModalHeader className="justify-center">
              <span className="border-b-4 border-persianIndigo pb-[10px] text-center font-main font-medium text-persianIndigo sm:text-[18px] xl:text-[20px]">
                {t('confirm_your_phone_number')}
              </span>
            </ModalHeader>
            <ModalBody>
              <OTPInput
                value={otp}
                onChange={setOtp}
                numInputs={6}
                containerStyle="w-full justify-center gap-[20px] mt-[40px] mb-[20px]"
                renderSeparator={null}
                renderInput={props => (
                  <input
                    {...props}
                    className="!sm:h-[73px] !sm:w-[52px] !h-[50px] !w-[30px] rounded-[17px] bg-lightPurple font-secondary font-bold text-persianIndigo outline-white"
                  />
                )}
              />
              <p className="sm:text[14px] mb-[40px] mr-[9px] text-center font-secondary text-persianIndigo xl:text-[16px]">
                {t('didnt_recieve_otp')}
                <span
                  className="cursor-pointer underline"
                  onClick={handleResendOtp}>
                  {t('send_code_again')}
                </span>
              </p>
            </ModalBody>
            <ModalFooter className="justify-center pb-4 sm:pb-0">
              <CustomButton
                value={t('register')}
                onClickHandling={handleVerification}
                btnStyles="w-[266px]"
                disabled={loading}
              />
            </ModalFooter>
          </>
        )}
      </ModalContent>
    </Modal>
  );
};

export default OtpModal;
