'use client';

import {useEffect, useMemo, useRef, useState} from 'react';
import {useLocale, useTranslations} from 'next-intl';
import {useDispatch, useSelector} from 'react-redux';
import {
  getCardAmount,
  getCart,
  getIsFinishingPayment,
  getIsWalletPaymentChosen,
  getToken,
  getUserData,
} from '@/selectors';
import {
  resetCarts,
  setGuestPaymentId,
  setIsAddingNewCard,
  setIsApplePayment,
  setIsFinishingPayment,
  setIsWalletPaymentChosen,
  setOrderAttributes,
} from '@/slices';
import {HiCreditCard} from 'react-icons/hi2';
import {FaApple} from 'react-icons/fa';

import {
  useCheckoutWithTamaraMutation,
  useCompleteCheckoutMutation,
  useCreateNewPaymentMutation,
  useGetPaymentMethodsQuery,
  useGetUserCreditCardsQuery,
  usePayWithStoreCreditsMutation,
} from '@/services';
import {ApplePay, CreditCardsSlide, PaymentModal, TamaraWidget} from '.';
import {Modal, ModalBody, ModalContent} from '@heroui/react';
import {logo, onePay, tamaraArBadge, tamaraEnBadge} from '@/assets';
import Image from 'next/image';
import {useRouter} from '@/hooks';
import {commonDisableCachingParameters, showErrorToast} from '@/common';
import {
  getGuestPaymentId,
  getIsApplePayment,
  getIsGuestPaymentStep,
} from '@/selectors/deliverySelector';
import {
  StackedCarousel,
  ResponsiveContainer,
} from 'react-stacked-center-carousel';
import {sendGTMEvent} from '@next/third-parties/google';
import {CustomButton} from '@/components/common';

const CreditCardsCarousel = () => {
  const t = useTranslations();
  const locale = useLocale();
  const router = useRouter();
  const dispatch = useDispatch();
  const [selectedCard, setSelectedCard] = useState(0);
  const [isToAddNewCard, setIsToAddNewCard] = useState(false);
  const ref = useRef();
  const isFinishingPayment = useSelector(getIsFinishingPayment);
  const isWalletPaymentChosen = useSelector(getIsWalletPaymentChosen);
  const cardAmount = useSelector(getCardAmount);
  const isGuestPaymentStep = useSelector(getIsGuestPaymentStep);
  const isApplePayment = useSelector(getIsApplePayment);
  const token = useSelector(getToken);
  const userData = useSelector(getUserData);

  const {data: paymentMethods} = useGetPaymentMethodsQuery(
    commonDisableCachingParameters,
  );
  const [completeCheckoutWithTamara] = useCheckoutWithTamaraMutation();

  const {data: userCreditCards} = useGetUserCreditCardsQuery(
    {locale},
    {
      skip: isGuestPaymentStep || !token,
    },
  );

  const [createNewPayment, {isLoading: isCreatingPayment}] =
    useCreateNewPaymentMutation();
  const [payWithStoreCredits, {isLoading: isCreatingPaymentWithStoreCredits}] =
    usePayWithStoreCreditsMutation();
  const [completeCheckout] = useCompleteCheckoutMutation();

  const cart = useSelector(getCart);
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;

  const isMacDevice = /Macintosh|Mac OS/i.test(userAgent);
  const isApplePayEnabled = window?.ApplePaySession?.canMakePayments() ?? false;

  useEffect(() => {
    setIsToAddNewCard(!isWalletPaymentChosen);
    if (isWalletPaymentChosen) {
      dispatch(setIsAddingNewCard(false));
    }
  }, [isWalletPaymentChosen]);

  const processStoreCredits = async amount => {
    return payWithStoreCredits({
      amount: amount.toString(),
    });
  };

  const createNewPaymentRequest = async (amount, selectedCardId) => {
    return createNewPayment({
      payment_method_id: paymentMethods?.creditPaymentMethod?.id,
      amount: amount,
      source_id: selectedCardId,
    });
  };

  const setOrderDetails = () => {
    dispatch(
      setOrderAttributes({
        orderNumber: cart?.number,
        orderToken: cart?.token,
      }),
    );
  };

  const completeCheckoutProcess = async () => {
    const completeResponse = await completeCheckout();
    if (
      completeResponse?.data?.data?.attributes?.state === 'complete' &&
      completeResponse?.data?.data?.attributes?.payment_state === 'paid'
    ) {
      dispatch(resetCarts());
      router.push('/rating');
      return {success: true};
    }
    return {error: t('payment_fail')};
  };

  const handlePaymentFlow = async (walletPaymentResponse, paymentResponse) => {
    if (!walletPaymentResponse?.error && !paymentResponse?.error) {
      setOrderDetails();
      dispatch(setIsFinishingPayment(true));
      const checkoutResult = await completeCheckoutProcess();
      if (checkoutResult?.success) {
        dispatch(setIsWalletPaymentChosen(false));
        sendGTMEvent({
          event: 'complete_purchase_trigger',
          ecommerce: {
            discount: cart?.discountAmount,
            coupon: cart?.couponCodes?.[0]?.code,
            value: cart?.total,
            items: cart.lineItems.map(({name, quantity, price}) => ({
              item_name: name,
              quantity,
              price,
            })),
          },
          ...(token &&
            userData?.userName && {
              name: userData.userName,
              email: userData.userEmail,
              phone_number: userData.userPhone,
            }),
        });
      } else {
        dispatch(setIsFinishingPayment(false));
        showErrorToast(t('payment_fail'));
      }
    } else {
      dispatch(setIsFinishingPayment(false));
      showErrorToast(
        walletPaymentResponse?.error?.data?.error ||
          paymentResponse?.error?.data?.error,
      );
    }
  };

  const handleGuestPaymentId = paymentResponse => {
    dispatch(
      setGuestPaymentId(
        paymentResponse?.data?.included?.[
          paymentResponse?.data?.included?.length - 1
        ]?.id,
      ),
    );
    sendGTMEvent({
      event: 'complete_purchase_trigger',
      ecommerce: {
        discount: cart?.discountAmount,
        coupon: cart?.couponCodes?.[0]?.code,
        value: cart?.total,
        transaction_id:
          paymentResponse?.data?.included?.[
            paymentResponse?.data?.included?.length - 1
          ]?.id,
        items: cart.lineItems.map(({name, quantity, price}) => ({
          item_name: name,
          quantity,
          price,
        })),
      },
      ...(token &&
        userData?.userName && {
          name: userData.userName,
          email: userData.userEmail,
          phone_number: userData.userPhone,
        }),
    });
    setOrderDetails();
  };

  const isToPayAsGuest = useMemo(
    () =>
      (!isApplePayment &&
        !isWalletPaymentChosen &&
        userCreditCards?.length === 0) ||
      isGuestPaymentStep,
    [
      isApplePayment,
      isWalletPaymentChosen,
      userCreditCards,
      isGuestPaymentStep,
    ],
  );

  const handlePayment = async myPaymentMethods => {
    if (isGuestPaymentStep || isApplePayment) {
      const paymentResponse = await createNewPayment({
        payment_method_id: isApplePayment
          ? myPaymentMethods?.applePaymentMethod?.id
          : myPaymentMethods?.guestPaymentMethod?.id,
        amount: cart?.total,
      });
      if (!paymentResponse.error) {
        handleGuestPaymentId(paymentResponse);
      }
    } else {
      if (isWalletPaymentChosen && cardAmount > 0 && cardAmount < cart?.total) {
        const walletResponse = await processStoreCredits(
          cart?.total - cardAmount,
        );
        if (isToAddNewCard || isToPayAsGuest) {
          const paymentResponse = await createNewPayment({
            payment_method_id: myPaymentMethods?.guestPaymentMethod?.id,
            amount: cart?.total,
          });
          if (!paymentResponse.error && !walletResponse.error) {
            handleGuestPaymentId(paymentResponse);
          }
        } else {
          const paymentResponse = await createNewPaymentRequest(
            cardAmount,
            selectedCard?.id,
          );
          handlePaymentFlow(walletResponse, paymentResponse);
        }
      } else if (isWalletPaymentChosen && cardAmount <= 0) {
        const walletResponse = await processStoreCredits(cart?.total);
        handlePaymentFlow(walletResponse, {});
      } else if (!isWalletPaymentChosen || cardAmount === cart?.total) {
        if (isToAddNewCard || isToPayAsGuest) {
          const paymentResponse = await createNewPayment({
            payment_method_id: myPaymentMethods?.guestPaymentMethod?.id,
            amount: cart?.total,
          });
          if (!paymentResponse.error) {
            handleGuestPaymentId(paymentResponse);
          }
        } else {
          const paymentResponse = await createNewPaymentRequest(
            cart?.total,
            selectedCard?.id,
          );
          handlePaymentFlow({}, paymentResponse);
        }
      }
    }
  };

  const handleApplePay = () => {
    dispatch(setIsApplePayment(true));
  };

  const handleNormalPayment = () => {
    dispatch(setIsApplePayment(false));
  };

  const paymentId = useSelector(getGuestPaymentId);

  const isPaymentButtonDisabled = useMemo(
    () => isWalletPaymentChosen && cardAmount > 0 && isToAddNewCard,
    [isWalletPaymentChosen, cardAmount, isToAddNewCard],
  );

  const handleAddingNewCard = () => {
    setIsToAddNewCard(true);
    dispatch(setIsAddingNewCard(true));
  };

  const handleBackToCardSelection = () => {
    setIsToAddNewCard(false);
    dispatch(setIsAddingNewCard(false));
  };

  const onHandlingCheckoutWithTamara = async () => {
    const paymentResponse = await createNewPayment({
      payment_method_id: paymentMethods?.tamaraPaymentMethod?.id,
      amount: cart?.total,
    });

    const tamaraResponse = await completeCheckoutWithTamara({
      payment_id:
        paymentResponse?.data?.included?.[
          paymentResponse?.data?.included?.length - 1
        ]?.id,
      redirect_url: `${process.env.NEXT_PUBLIC_FRONT_END_SERVER}/${locale}/complete-payment`,
    });

    if (!tamaraResponse?.error) {
      setOrderDetails();
      router.push(tamaraResponse?.data.data.attributes.transaction_url);
    } else {
      showErrorToast(t('payment_fail'));
    }
  };

  const maxVisibleSlide = useMemo(
    () => (userCreditCards?.length > 1 ? 3 : 1),
    [userCreditCards],
  );

  const requiredScalesLength = useMemo(
    () => Math.ceil((maxVisibleSlide + 3) / 2),
    [maxVisibleSlide],
  );

  const customScales = useMemo(
    () => Array.from({length: requiredScalesLength}, (_, i) => 1 - i * 0.2),
    [requiredScalesLength],
  );

  return (
    <>
      <div className="mt-5 flex flex-col">
        <div className="flex items-center pt-4">
          <Image src={onePay} alt="1Pay" width={20} height={20} />
          <p className="font-main text-[16px] font-medium text-persianIndigo xl:text-[1.1vw]">
            {t('payment')}
          </p>
        </div>
        <div className="flex w-[18rem] items-center gap-[0.75rem] py-4">
          <div
            className={`flex h-[2.5rem] w-[3rem] cursor-pointer items-center justify-center rounded-md bg-black p-2 ${isApplePayment ? 'bg-white' : 'bg-black'}`}
            onClick={handleNormalPayment}>
            <HiCreditCard color={isApplePayment ? 'black' : 'white'} />
          </div>
          {isMacDevice && isApplePayEnabled && (
            <div
              className={`flex h-[2.5rem] w-[3rem] cursor-pointer items-center justify-center rounded-md p-2 ${isApplePayment ? 'bg-black' : 'bg-white'}`}
              onClick={handleApplePay}>
              <FaApple color={isApplePayment ? 'white' : 'black'} />
            </div>
          )}

          {paymentMethods?.tamaraPaymentMethod && (
            <div
              className="h-[2.5rem] w-[4.5rem] cursor-pointer"
              onClick={onHandlingCheckoutWithTamara}>
              <Image
                src={locale === 'en' ? tamaraEnBadge : tamaraArBadge}
                alt="tamara badge"
                width={48}
                height={40}
                className="h-[2.5rem] w-[4.5rem]"
              />
            </div>
          )}
        </div>
        {isToPayAsGuest && !isApplePayment ? (
          <PaymentModal
            paymentId={paymentId}
            handlePayment={handlePayment}
            isCreatingPayment={isCreatingPayment}
            isCreatingPaymentWithStoreCredits={
              isCreatingPaymentWithStoreCredits
            }
          />
        ) : (
          userCreditCards?.length > 0 &&
          !isApplePayment && (
            <div style={{width: '100%', position: 'relative'}}>
              <ResponsiveContainer
                carouselRef={ref}
                render={(parentWidth, carouselRef) => {
                  return (
                    <>
                      <StackedCarousel
                        ref={carouselRef}
                        slideComponent={props => (
                          <CreditCardsSlide
                            isWalletPaymentChosen={isWalletPaymentChosen}
                            {...props}
                          />
                        )}
                        onActiveSlideChange={newSlide => {
                          setSelectedCard(userCreditCards[newSlide]);
                          setIsToAddNewCard(false);
                          dispatch(setIsAddingNewCard(false));
                        }}
                        slideWidth={parentWidth}
                        carouselWidth={parentWidth}
                        data={userCreditCards}
                        currentVisibleSlide={maxVisibleSlide}
                        maxVisibleSlide={maxVisibleSlide}
                        useGrabCursor
                        customScales={customScales}
                        className="mb-4"
                      />
                      {!isWalletPaymentChosen &&
                        (isToAddNewCard ? (
                          <div className="flex justify-center">
                            <CustomButton
                              btnType="tertiary"
                              className="font-secondary text-[16px] font-normal text-persianIndigo underline sm:text-[12px] xl:text-[0.9vw]"
                              value={t('use_existing_card')}
                              onClickHandling={handleBackToCardSelection}
                            />
                          </div>
                        ) : (
                          <div className="flex justify-center">
                            <CustomButton
                              btnType="tertiary"
                              className="font-secondary text-[16px] font-normal text-persianIndigo underline sm:text-[12px] xl:text-[0.9vw]"
                              value={t('add_new_cardd')}
                              onClickHandling={handleAddingNewCard}
                            />
                          </div>
                        ))}
                      {isToAddNewCard &&
                      !isWalletPaymentChosen &&
                      !isApplePayment ? (
                        <PaymentModal
                          paymentId={paymentId}
                          handlePayment={handlePayment}
                          isCreatingPayment={isCreatingPayment}
                          isCreatingPaymentWithStoreCredits={
                            isCreatingPaymentWithStoreCredits
                          }
                        />
                      ) : (
                        <CustomButton
                          btnStyles="mt-5 w-full"
                          isDisabled={isPaymentButtonDisabled}
                          onClickHandling={handlePayment}
                          isLoading={
                            isCreatingPayment ||
                            isCreatingPaymentWithStoreCredits
                          }
                          value={t('place_order')}
                        />
                      )}
                    </>
                  );
                }}
              />
            </div>
          )
        )}
        {isWalletPaymentChosen && userCreditCards?.length === 0 && (
          <CustomButton
            btnStyles="mt-5 w-full"
            isDisabled={isPaymentButtonDisabled}
            onClickHandling={handlePayment}
            isLoading={isCreatingPayment || isCreatingPaymentWithStoreCredits}
            value={t('place_order')}
          />
        )}

        {isApplePayment && (
          <div className="mt-4">
            <ApplePay handlePayment={handlePayment} paymentId={paymentId} />
          </div>
        )}
      </div>
      <Modal
        isOpen={isFinishingPayment}
        size="xs"
        classNames={{
          closeButton: 'hidden',
          wrapper: 'items-center',
        }}>
        <ModalContent>
          <ModalBody className="flex items-center justify-center p-4">
            <Image
              src={logo}
              alt="loading payment"
              className="w-[180px] animate-continuousFadeInOut"
            />
            <p className="font-secondary text-[12px] font-normal text-persianIndigo">
              {t('please_be_patient_while_processing_your_order')}
            </p>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default CreditCardsCarousel;
