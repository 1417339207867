'use client';

import {useEffect, useMemo, useState} from 'react';
import {
  GiftCardImageModal,
  LinkModal,
  VideoRecorderModal,
  GiftCardForm,
  GiftCardPreview,
} from '../Products';
import {useGetCardDesignsQuery} from '@/services';
import {useDispatch, useSelector} from 'react-redux';
import {getCart, getSelectedImageURL} from '@/selectors';
import {commonDisableCachingParameters} from '@/common';
import {useLocale} from 'next-intl';
import {setSelectedImageURL} from '@/slices';

const CheckoutGiftCard = ({
  setWrittenCardData,
  isGiftCardShown,
  setIsGiftCardShown,
  onHandlingChosenGiftCard,
}) => {
  const locale = useLocale();
  const dispatch = useDispatch();
  const [isVideoModalOpen, setIsVideoModalOpen] = useState(false);
  const [isLinkModalOpen, setIsLinkModalOpen] = useState(false);
  const selectedImage = useSelector(getSelectedImageURL);
  const [formData, setFormData] = useState({
    to: '',
    from: '',
    message: '',
  });
  const [link, setLink] = useState('');
  const [writtenImage, setWrittenImage] = useState(null);
  const [video, setVideo] = useState(null);

  const order = useSelector(getCart);

  const handleLinkChange = e => {
    setLink(e.target.value);
  };

  const {data: cardDesigns, error: getCardDesignsError} =
    useGetCardDesignsQuery({locale}, commonDisableCachingParameters);

  useEffect(() => {
    dispatch(setSelectedImageURL(cardDesigns?.freeCardUrl));
  }, [cardDesigns]);

  const handleRecordVideoClick = () => {
    setIsVideoModalOpen(true);
  };

  const handlePasteLinkClick = () => {
    setIsLinkModalOpen(true);
  };

  const handleImageSelect = image => {
    dispatch(setSelectedImageURL(image));
  };

  const handleFormChange = data => {
    setFormData(data);
  };

  const uploadWrittenCard = () => {
    const formdata = new FormData();
    if (video) {
      formdata.append('video', video);
    }
    formdata.append('order_id', order?.id);
    formdata.append('message', formData?.message);
    formdata.append('from', formData?.from);
    formdata.append('to', formData?.to);
    formdata.append('media_link', link);
    formdata.append('image', writtenImage);

    setWrittenCardData(formdata);
  };

  useEffect(() => {
    uploadWrittenCard();
  }, [order, formData, link, writtenImage, video]);

  useEffect(() => {
    setIsGiftCardShown(false);
  }, [formData]);

  const isToShowWrittenCard = useMemo(
    () =>
      selectedImage &&
      (formData.to || formData.message || formData.from) &&
      isGiftCardShown,
    [selectedImage, formData, isGiftCardShown],
  );

  if (getCardDesignsError) {
    throw new Error('Failed to load card designs.');
  }

  return (
    <>
      <div className="mb-8 flex w-full flex-col gap-4 sm:mb-6 xl:flex-row">
        {isToShowWrittenCard ? (
          <GiftCardPreview
            image={selectedImage}
            setImageURL={setWrittenImage}
            formData={{
              to: formData.to,
              message: formData.message,
              from: formData.from,
            }}
            urlExists={link || video}
            isCheckout={true}
          />
        ) : (
          <GiftCardImageModal
            onImageSelect={handleImageSelect}
            cardDesigns={cardDesigns}
            isCheckout={true}
            onHandlingChoosenGiftCard={onHandlingChosenGiftCard}
          />
        )}
        <GiftCardForm
          handleRecordVideoClick={handleRecordVideoClick}
          handlePasteLinkClick={handlePasteLinkClick}
          formData={formData}
          onFormChange={handleFormChange}
          isCheckout={true}
          setIsGiftCardShown={setIsGiftCardShown}
        />
      </div>
      <VideoRecorderModal
        isVideoModalOpen={isVideoModalOpen}
        setIsVideoModalOpen={setIsVideoModalOpen}
        setVideo={setVideo}
      />
      <LinkModal
        isLinkModalOpen={isLinkModalOpen}
        setIsLinkModalOpen={setIsLinkModalOpen}
        link={link}
        handleLinkChange={handleLinkChange}
      />
    </>
  );
};

export default CheckoutGiftCard;
