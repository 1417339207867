'use client';

import {useRouter} from '@/hooks';
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from '@heroui/react';
import {useLocale, useTranslations} from 'next-intl';
import {useMemo, useState} from 'react';
import {CustomButton} from '@/components/common';
import {useCancelOrderMutation} from '@/services';
import {showErrorToast} from '@/common';
import {sendGTMEvent} from '@next/third-parties/google';

const CancelOrderModal = ({
  isOrderCancellationModalOpen,
  setIsOrderCancellationModalOpen,
  order,
}) => {
  const t = useTranslations();
  const router = useRouter();
  const [selectedReason, setSelectedReason] = useState(null);
  const [allowCancel, setAllowCancel] = useState(true);

  const reasons = [
    {label: t('wrong_order'), id: 1},
    {label: t('changed_my_mind'), id: 2},
    {label: t('wrong_color'), id: 3},
    {label: t('change_address'), id: 4},
    {label: t('change_delivery_method'), id: 5},
    {label: t('other'), id: 6},
  ];

  const [cancelOrder, {isLoading: isCancelingOrder}] = useCancelOrderMutation();

  const handleCircleClick = reason => {
    setAllowCancel(true);
    setSelectedReason(reason === selectedReason ? null : reason);
  };

  const handleCancelOrder = async () => {
    if (!selectedReason) {
      setAllowCancel(false);
      return;
    }
    const response = await cancelOrder({
      order_number: order?.number,
      cancellation_reason: selectedReason.label,
    });
    if (!response.error) {
      sendGTMEvent({event: 'cancel_order_trigger'});
      router.replace(
        `/orders/${order?.number}/cancel?reason=${selectedReason.label}`,
      );
    } else {
      showErrorToast(response?.error?.error);
    }
  };

  const isOrderCancellationDisabled = useMemo(
    () =>
      order?.shipment_state === 'canceled' ||
      order?.shipment_state !== 'pending',
    [order],
  );

  return (
    <Modal
      isOpen={isOrderCancellationModalOpen}
      size="md"
      onClose={() => setIsOrderCancellationModalOpen(false)}
      classNames={{
        closeButton:
          '!hidden !text-[22px] !text-persianIndigo hover:!bg-thistle font-main !p-0 !rounded-md mt-4 sm:block',
        wrapper: 'items-center',
      }}
      className="mx-4 rounded-[2rem]">
      <ModalContent>
        <ModalHeader className="flex">
          <p className="font-main text-[14px] font-medium text-persianIndigo sm:text-[20px]">
            {t('why_do_you_want_to_cancel_your_order')}
          </p>
          <CustomButton
            btnType="tertiary"
            value={'X'}
            btnStyles="text-[1.375rem] text-persianIndigo !hover:!bg-none hidden sm:block"
            onClickHandling={() => setIsOrderCancellationModalOpen(false)}
          />
        </ModalHeader>

        <ModalBody>
          <div className="mb-[2.5rem] flex flex-col gap-2">
            {reasons.map((reason, index) => (
              <div
                key={index}
                className={`flex cursor-pointer items-center justify-between rounded-[1rem] border-2 px-4 py-3 ${
                  selectedReason?.id === reason.id
                    ? 'border-persianIndigo'
                    : 'border-thistle'
                }`}
                onClick={() => handleCircleClick(reason)}>
                <p className="font-secondary text-[16px] font-normal text-persianIndigo">
                  {reason.label}
                </p>
                <div
                  className={`flex h-5 w-5 items-center justify-center rounded-full border-2 ${
                    selectedReason?.id === reason.id
                      ? 'border-persianIndigo'
                      : 'border-thistle'
                  }`}>
                  {selectedReason?.id === reason.id && (
                    <div className="h-2 w-2 rounded-full bg-persianIndigo" />
                  )}
                </div>
              </div>
            ))}
            {!allowCancel && (
              <p className="font-secondary text-[16px] font-normal text-pink">
                {t('select_reason_to_cancel_order')}
              </p>
            )}
          </div>
        </ModalBody>

        <ModalFooter className="mb-[1.5rem] flex flex-col">
          <CustomButton
            value={t('cancel_order')}
            onClickHandling={handleCancelOrder}
            isLoading={isCancelingOrder}
            isDisabled={isOrderCancellationDisabled}
            btnStyles={`${isOrderCancellationDisabled ? 'opacity-50 cursor-not-allowed' : 'opacity-100 cursor-pointer'}`}
          />
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default CancelOrderModal;
