'use client';

import {
  useCreatePaymentMutation,
  useGetAllPaymentMethodsQuery,
  useGetUnpaidOrderQuery,
  useLazyGetUnpaidOrderPreparationAbilityQuery,
  usePayAsGuestMutation,
} from '@/services';
import {useSearchParams} from 'next/navigation';
import {useEffect, useMemo, useState} from 'react';
import {CustomButton} from '@/components/common';
import {useLocale, useTranslations} from 'next-intl';
import {IoIosArrowForward, IoIosArrowDown} from 'react-icons/io';
import {
  capitalizeEachWord,
  commonDisableCachingParameters,
  convertEnglishNumbersToArabic,
  showErrorToast,
} from '@/common';
import {useMediaQuery} from 'react-responsive';
import {useDispatch, useSelector} from 'react-redux';
import {
  setIsAddingNewCard,
  setIsApplePayment,
  setIsFinishingPayment,
  setOrderAttributes,
} from '@/slices';
import {useRouter} from '@/hooks';
import {Modal, ModalBody, ModalContent} from '@heroui/react';
import Image from 'next/image';
import {logo} from '@/assets';
import {getIsApplePayment, getIsFinishingPayment} from '@/selectors';
import {HiCreditCard} from 'react-icons/hi2';
import {FaApple} from 'react-icons/fa';
import {ApplePay} from '.';
import {sendGTMEvent} from '@next/third-parties/google';

import dynamic from 'next/dynamic';

const CardSdk = dynamic(() => import('@tap-payments/card-sdk'), {
  ssr: false,
});

const {Currencies, Direction, Edges, Locale, TapCard, Theme, tokenize} =
  CardSdk;

const OrderPayment = () => {
  const t = useTranslations();
  const locale = useLocale();
  const dispatch = useDispatch();
  const params = useSearchParams();
  const router = useRouter();
  const isMobile = useMediaQuery({query: '(max-width: 640px)'});
  const [isPaymentSDKLoading, setIsPaymentSDKLoading] = useState(true);
  const [preparationError, setPreparationError] = useState(null);
  const [isOpen, setIsOpen] = useState(true);
  const isFinishingPayment = useSelector(getIsFinishingPayment);
  const isApplePayment = useSelector(getIsApplePayment);

  const userAgent = navigator.userAgent || navigator.vendor || window.opera;

  const isMacDevice = /Macintosh|Mac OS/i.test(userAgent);
  const isApplePayEnabled = window?.ApplePaySession?.canMakePayments() ?? false;

  const token = useMemo(() => params?.get('token'), [params]);

  const {data: paymentMethods, isLoading: isGettingPaymentMethods} =
    useGetAllPaymentMethodsQuery({token}, commonDisableCachingParameters);

  const {data: unpaidOrder, isLoading: isGettingUnpaidOrder} =
    useGetUnpaidOrderQuery({
      token,
      params: {include: 'line_items.quantity,user,shopping_method'},
    });

  const [checkForAbilityToPay] = useLazyGetUnpaidOrderPreparationAbilityQuery();

  const [createPayment, {isLoading: isCreatingPayment}] =
    useCreatePaymentMutation();

  const [createCharge, {isLoading: isPayingAsGuest}] = usePayAsGuestMutation();

  const handleStopingLoading = () => {
    setIsPaymentSDKLoading(false);
  };

  useEffect(() => {
    if (!isMobile) {
      setIsOpen(true);
    }
  }, [isMobile]);

  const handleError = error => {
    showErrorToast(error);
    handleStopingLoading();
    router.replace('/checkout/2');
  };

  const toggleSummary = () => {
    if (isMobile) {
      setIsOpen(!isOpen);
    }
  };

  const setOrderDetails = () => {
    dispatch(
      setOrderAttributes({
        orderNumber: unpaidOrder?.number,
        orderToken: unpaidOrder?.token,
      }),
    );
  };

  const buildRedirectUrl = transactionUrl => {
    const postUrl = `${process.env.NEXT_PUBLIC_API_URL}/api/v2/storefront/tap_payment_results`;
    return `${transactionUrl}&post_url=${postUrl}`;
  };

  const handleSuccess = async data => {
    const response = await checkForAbilityToPay({token});
    if (response?.error) {
      handlePreparationError(response?.error?.error);
      return;
    }
    await handleNonApplePayment(data);
  };

  const handleNonApplePayment = async data => {
    dispatch(setIsFinishingPayment(true));

    const paymentResponse = await createPayment({
      token,
      body: {
        payment_method_id: paymentMethods?.guestPaymentMethod?.id,
        amount: unpaidOrder?.total,
      },
    });

    if (paymentResponse?.error) {
      showErrorToast(paymentResponse?.error?.error);
      dispatch(setIsFinishingPayment(false));
      return;
    }

    setOrderDetails();
    dispatch(setIsAddingNewCard(true));

    const paymentId = getLastPaymentId(paymentResponse?.data?.included);
    const tokenId = data?.id;

    if (tokenId && paymentId) {
      const chargeResponse = await createCharge({
        payment_id: paymentId,
        token_id: tokenId,
        redirect_url: `${process.env.NEXT_PUBLIC_FRONT_END_SERVER}/${locale}/complete-payment`,
      });

      if (chargeResponse?.error) {
        showErrorToast(chargeResponse?.error?.error);
      } else {
        sendGTMEvent({event: 'checkout_link_payment_trigger'});
        const transactionUrl =
          chargeResponse?.data?.data?.attributes?.transaction_url;
        router.push(buildRedirectUrl(transactionUrl));
      }
    }

    dispatch(setIsFinishingPayment(false));
  };

  const handlePreparationError = errorData => {
    setPreparationError(
      t('preparation_error', {
        productName: errorData?.product_name,
        productPrepHours: errorData?.preparation_time,
      }),
    );
  };

  const getLastPaymentId = includedData => {
    return includedData?.[includedData?.length - 1]?.id;
  };

  const handleApplePay = () => {
    dispatch(setIsApplePayment(true));
  };

  const handleNormalPayment = () => {
    dispatch(setIsApplePayment(false));
  };

  return (
    <>
      <div className="ml-[10vw] flex w-[50vw] items-center gap-[0.75rem] py-4 xl:ml-[15vw]">
        <div
          className={`flex h-[2.5rem] w-[3rem] cursor-pointer items-center justify-center rounded-md bg-black p-2 ${isApplePayment ? 'bg-white' : 'bg-black'}`}
          onClick={handleNormalPayment}>
          <HiCreditCard color={isApplePayment ? 'black' : 'white'} />
        </div>
        {isMacDevice && isApplePayEnabled && (
          <div
            className={`flex h-[2.5rem] w-[3rem] cursor-pointer items-center justify-center rounded-md p-2 ${isApplePayment ? 'bg-black' : 'bg-white'}`}
            onClick={handleApplePay}>
            <FaApple color={isApplePayment ? 'white' : 'black'} />
          </div>
        )}
      </div>
      <div
        className={`mb-[15vh] flex items-start justify-center xl:mb-[30vh] ${isMobile ? 'flex-col-reverse gap-[3rem]' : 'flex-row gap-[8rem]'}`}>
        <div className="flex w-[70vw] flex-col items-center self-center sm:w-[35vw] xl:w-[30vw]">
          <div className="w-[70vw] sm:w-[35vw] xl:w-[30vw]">
            {isApplePayment ? (
              <ApplePay
                order={unpaidOrder}
                isCheckoutLink={true}
                orderToken={token}
              />
            ) : (
              <>
                <TapCard
                  showLoader={isPaymentSDKLoading}
                  publicKey={process.env.NEXT_PUBLIC_TAP_PAYMENT_PUBLIC_KEY}
                  merchant={{
                    id: process.env.NEXT_PUBLIC_MERCHANT_ID,
                  }}
                  transaction={{
                    amount: unpaidOrder?.total,
                    currency: Currencies.SAR,
                  }}
                  customer={unpaidOrder?.user}
                  acceptance={{
                    supportedBrands: ['AMEX', 'VISA', 'MASTERCARD', 'MADA'],
                    supportedCards: ['CREDIT', 'DEBIT'],
                  }}
                  fields={{
                    cardHolder: true,
                  }}
                  addons={{
                    displayPaymentBrands: true,
                    loader: true,
                    saveCard: false,
                    tokenize: true,
                  }}
                  interface={{
                    locale: Locale.EN,
                    theme: Theme.LIGHT,
                    edges: Edges.CURVED,
                    direction: Direction.LTR,
                  }}
                  onReady={handleStopingLoading}
                  onError={error => handleError(error)}
                  onSuccess={data => handleSuccess(data)}
                />
                <CustomButton
                  id="checkout-link-order"
                  btnStyles="mt-5 w-full"
                  onClickHandling={tokenize}
                  isLoading={isCreatingPayment || isPayingAsGuest}
                  value={t('place_order')}
                />
              </>
            )}
          </div>
          {preparationError && (
            <div className="my-2 font-secondary text-[12px] text-pink">
              <p className="font-semiBold text-[14px]">
                {t('unable_to_place_order_at_the_moment')}
              </p>
              <p>{preparationError}</p>
            </div>
          )}
        </div>
        <div
          className={`w-[90vw] bg-lightPurple px-[2.5rem] py-4 text-persianIndigo sm:w-[35vw] xl:w-[30vw]`}>
          <div
            className="mb-4 flex items-center justify-between"
            onClick={toggleSummary}>
            <div className="flex items-center gap-4">
              {isMobile &&
                (isOpen ? (
                  <IoIosArrowDown size={15} className="text-persianIndigo" />
                ) : (
                  <IoIosArrowForward size={15} className="text-persianIndigo" />
                ))}
              <p className="font-main font-medium sm:mb-4 sm:text-[14px] xl:text-[20px]">
                {t('order_summary')}
              </p>
            </div>

            <p className="font-main font-medium sm:mb-4 sm:text-[12px] xl:text-[18px]">
              {convertEnglishNumbersToArabic(unpaidOrder?.total, locale)}{' '}
              {t('sar')}
            </p>
          </div>
          {isOpen && (
            <>
              {unpaidOrder?.lineItems?.map((item, index) => (
                <div key={index} className="flex items-center justify-between">
                  <p className="font-secondary text-[12px] sm:font-normal xl:text-[16px] xl:font-normal">
                    {convertEnglishNumbersToArabic(item.quantity, locale)} x{' '}
                    {capitalizeEachWord(item.name)}
                    {item.optionsText ? ' - ' : ''}
                    {capitalizeEachWord(item.optionsText.split(' ')[1])}
                  </p>
                  <p className="font-secondary text-[12px] sm:font-normal xl:text-[16px] xl:font-normal">
                    {convertEnglishNumbersToArabic(item.total, locale)}{' '}
                    {t('sar')}
                  </p>
                </div>
              ))}
              {unpaidOrder?.isDelivery && (
                <div className="flex items-center justify-between">
                  <p className="font-secondary text-[12px] sm:font-normal xl:text-[16px] xl:font-normal">
                    {t('delivery_fees')}
                  </p>
                  <p className="font-secondary text-[12px] text-persianIndigo sm:font-normal xl:text-[16px] xl:font-normal">
                    {convertEnglishNumbersToArabic(
                      unpaidOrder?.shipTotal,
                      locale,
                    )}{' '}
                    {t('sar')}
                  </p>
                </div>
              )}
            </>
          )}
        </div>
      </div>
      <Modal
        isOpen={isFinishingPayment}
        size="xs"
        classNames={{
          closeButton: 'hidden',
          wrapper: 'items-center',
        }}>
        <ModalContent>
          <ModalBody className="flex items-center justify-center">
            <Image
              src={logo}
              alt="loading payment"
              className="w-[180px] animate-continuousFadeInOut"
            />
            <p className="font-secondary text-[12px] font-normal text-persianIndigo">
              {t('please_be_patient_while_processing_your_order')}
            </p>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default OrderPayment;
