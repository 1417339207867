'use client';

import {useMemo, useState} from 'react';
import {
  getCart,
  getIsGuestPaymentStep,
  getToken,
  getUserData,
} from '@/selectors';
import {useLocale, useTranslations} from 'next-intl';
import {
  useGetUserQuery,
  useGetPaymentMethodsQuery,
  usePayAsGuestMutation,
  useCreateNewPaymentMutation,
  useCreatePaymentMutation,
} from '@/services';
import {useDispatch, useSelector} from 'react-redux';
import {commonDisableCachingParameters, showErrorToast} from '@/common';
import {useRouter} from '@/hooks';
import {setIsFinishingPayment, setOrderAttributes} from '@/slices';
import {Spinner} from '@heroui/react';
import {sendGTMEvent} from '@next/third-parties/google';
import * as ApplePayBtn from '@tap-payments/apple-pay-button';

const {ApplePayButton, Environment, Scope} = ApplePayBtn;

const ApplePay = ({order, orderToken, isCheckoutLink}) => {
  const dispatch = useDispatch();
  const locale = useLocale();
  const router = useRouter();
  const t = useTranslations();
  const cart = useSelector(getCart);
  const isGuestPaymentStep = useSelector(getIsGuestPaymentStep);
  const [createNewPayment] = useCreateNewPaymentMutation();
  const [createPayment] = useCreatePaymentMutation();
  const [isApplePayReady, setIsApplePayReady] = useState(false);
  const userData = useSelector(getUserData);
  const token = useSelector(getToken);

  const {data: user} = useGetUserQuery(
    {locale},
    {
      skip: isGuestPaymentStep,
      ...commonDisableCachingParameters,
    },
  );

  const {data: paymentMethods} = useGetPaymentMethodsQuery();

  const [createCharge] = usePayAsGuestMutation();

  const finalOrder = useMemo(() => {
    return order || cart;
  }, [order, cart]);

  const applePayId = useMemo(() => {
    return paymentMethods?.applePaymentMethod?.id;
  }, [paymentMethods]);

  const handleTransactionComplete = async (token_id, payment_id) => {
    const response = await createCharge({payment_id, token_id});
    if (!response?.error) {
      isCheckoutLink
        ? sendGTMEvent({event: 'checkout_link_payment_trigger'})
        : sendGTMEvent({
            event: 'complete_purchase_trigger',
            ecommerce: {
              discount: cart?.discountAmount,
              coupon: cart?.couponCodes?.[0]?.code,
              value: cart?.total,
              transaction_id: payment_id,
              items: cart.lineItems.map(({name, quantity, price}) => ({
                item_name: name,
                quantity,
                price,
              })),
            },
            ...(token &&
              userData?.userName && {
                name: userData.userName,
                email: userData.userEmail,
                phone_number: userData.userPhone,
              }),
          });
      setOrderDetails();
      router.replace(
        `${process.env.NEXT_PUBLIC_FRONT_END_SERVER}/${locale}/complete-payment`,
      );
    } else {
      showErrorToast(t('payment_fail'));
    }
  };
  const setOrderDetails = () => {
    dispatch(
      setOrderAttributes({
        orderNumber: finalOrder?.number,
        orderToken: finalOrder?.token,
      }),
    );
  };

  const handleSuccess = async token => {
    dispatch(setIsFinishingPayment(true));

    try {
      const paymentData = order
        ? await createPayment({
            token: orderToken,
            body: {
              payment_method_id: applePayId,
              amount: finalOrder?.total,
            },
          })
        : await createNewPayment({
            payment_method_id: applePayId,
            amount: finalOrder?.total,
          });

      const paymentId = paymentData?.data?.included?.at(-1)?.id;

      await handleTransactionComplete(token?.id, paymentId);
    } catch (error) {
      showErrorToast(t('payment_fail'));
    } finally {
      dispatch(setIsFinishingPayment(false));
    }
  };

  return (
    <div className="relative">
      {!isApplePayReady && (
        <div className="absolute inset-0 z-50 flex items-center justify-center bg-white bg-opacity-25">
          <Spinner color="secondary" />
        </div>
      )}
      <ApplePayButton
        debug={!process.env.NEXT_PUBLIC_IS_PRODUCTION}
        scope={Scope.TapToken}
        publicKey={process.env.NEXT_PUBLIC_TAP_PAYMENT_PUBLIC_KEY}
        environment={
          process.env.NEXT_PUBLIC_IS_PRODUCTION
            ? Environment.Production
            : Environment.Development
        }
        merchant={{
          id: process.env.NEXT_PUBLIC_MERCHANT_ID,
          domain: process.env.NEXT_PUBLIC_MERCHANT_DOMAIN,
        }}
        acceptance={{
          supportedBrands: ['mada', 'masterCard', 'visa'],
        }}
        features={{
          supportsCouponCode: false,
          shippingContactFields: ['name', 'phone', 'email'],
        }}
        transaction={{
          currency: 'SAR',
          amount: finalOrder?.total,
          items: [],
        }}
        customer={{
          name: [
            {
              locale: locale,
              first: user?.data?.attributes?.first_name || 'N/A',
              last: user?.data?.attributes?.last_name || 'N/A',
            },
          ],
          contact: {
            email: user?.data?.attributes?.email || 'support@springrose.com',
            phone: {
              number: user?.data?.attributes?.phone_number || '',
              countryCode: '+966',
            },
          },
        }}
        interface={{
          locale: locale,
          theme: 'dark',
          type: 'buy',
          edges: 'curved',
        }}
        onSuccess={data => handleSuccess(data)}
        onReady={() => setIsApplePayReady(true)}
      />
    </div>
  );
};

export default ApplePay;
