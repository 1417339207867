'use client';

import {phone_label} from '@/assets';
import {Input} from '@heroui/react';
import Image from 'next/image';
import {useLocale, useTranslations} from 'next-intl';
import {rules, showErrorToast} from '@/common';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import {useDispatch, useSelector} from 'react-redux';
import {
  closeOtpModal,
  openOtpModal,
  setIsGuestPaymentStep,
  updateGuestCredentials,
} from '@/slices';
import {
  useRequestGuestOtpMutation,
  useVerifyGuestOtpMutation,
} from '@/services';
import {getGuestCredentials, getIsOTPModalOpen} from '@/selectors';
import {CustomButton} from '@/components/common';
import {OtpModal} from '@/components/Auth';

const GuestUserLoginTab = () => {
  const locale = useLocale();
  const t = useTranslations();
  const dispatch = useDispatch();
  const [requestGuestOtp] = useRequestGuestOtpMutation();
  const [verifyGuestOtp] = useVerifyGuestOtpMutation();
  const isOtpModalOpen = useSelector(getIsOTPModalOpen);
  const guestCredentials = useSelector(getGuestCredentials);

  const validationSchema = Yup.object({
    phoneNumber: rules('phoneNumber', null, t),
    fullName: rules('name', null, t),
  });

  const formik = useFormik({
    initialValues: {
      phoneNumber: '',
      fullName: '',
    },
    validationSchema,
    onSubmit: async values => {
      dispatch(
        updateGuestCredentials({
          guestPhoneNumber: values.phoneNumber,
          guestFullName: values.fullName,
          isDataValid: false,
        }),
      );
      const response = await requestGuestOtp({
        phone_number: `966${values.phoneNumber}`,
      });
      if (response?.error) {
        showErrorToast(response.error.error);
      } else {
        dispatch(openOtpModal());
        dispatch(setIsGuestPaymentStep(true));
      }
    },
  });

  const onVerificationSuccess = () => {
    dispatch(
      updateGuestCredentials({
        ...guestCredentials,
        isDataValid: true,
      }),
    );
    dispatch(closeOtpModal());
  };

  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <div className="flex w-full flex-col">
          <div className="mb-2 font-secondary">
            <Input
              type="text"
              name="fullName"
              variant="underlined"
              label={t('name')}
              value={formik.values.fullName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className="w-full text-[16px]"
              classNames={{
                label: '!text-thistle',
                inputWrapper:
                  '!border-thistle hover:border-thistle focus:border-thistle focus:border-[5px] !outline-0 after:bg-persianIndigo',
                input: '!text-persianIndigo',
              }}
            />
            {formik.touched.fullName && formik.errors.fullName && (
              <div className={`mt-2 font-secondary text-[12px] text-red-500`}>
                {formik.errors.fullName}
              </div>
            )}
          </div>
          <div className="mb-2 w-full font-secondary">
            <Input
              type="text"
              variant="underlined"
              name="phoneNumber"
              startContent={
                <div
                  className={`${locale === 'ar' ? 'ml-2' : 'mr-2'} flex w-[7.3rem] items-center gap-3 md:w-[6.3rem]`}>
                  <Image
                    width={35}
                    height={10}
                    src={phone_label}
                    alt="phone label"
                  />
                  <p className="text-[14px] text-persianIndigo">+966</p>
                </div>
              }
              value={formik.values.phoneNumber}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className="w-full text-[16px]"
              classNames={{
                label: '!text-thistle',
                inputWrapper:
                  '!border-thistle hover:border-thistle focus:border-thistle focus:border-[5px] !outline-0 after:bg-persianIndigo',
                input: '!text-persianIndigo',
              }}
            />
            {formik.touched.phoneNumber && formik.errors.phoneNumber && (
              <div className={`mt-2 font-secondary text-[12px] text-red-500`}>
                {formik.errors.phoneNumber}
              </div>
            )}
          </div>
        </div>
        <CustomButton
          btnActionType="submit"
          btnStyles="mt-4 w-full h-[52px]"
          value={t('continue')}
        />
      </form>
      <OtpModal
        isOpen={isOtpModalOpen}
        userPhoneNumber={`966${formik.values.phoneNumber}`}
        onRequestOtp={() =>
          requestGuestOtp({phone_number: `966${formik.values.phoneNumber}`})
        }
        onVerifyOtp={otp =>
          verifyGuestOtp({
            phone_number: `966${formik.values.phoneNumber}`,
            otp_code: otp,
          })
        }
        onVerificationSuccess={onVerificationSuccess}
      />
    </div>
  );
};

export default GuestUserLoginTab;
