'use client';

import {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {
  getCart,
  getIsGuestPaymentStep,
  getToken,
  getUserData,
} from '@/selectors';
import {useGetUserQuery, usePayAsGuestMutation} from '@/services';
import {useLocale} from 'next-intl';
import {useRouter} from '@/hooks';
import {commonDisableCachingParameters} from '@/common';
import dynamic from 'next/dynamic';
import {sendGTMEvent} from '@next/third-parties/google';

const GoSellElement = dynamic(() => import('./GoSellElement'), {
  ssr: false,
});

const PaymentModal = ({
  paymentId,
  handlePayment,
  isCreatingPayment,
  isCreatingPaymentWithStoreCredits,
}) => {
  const isGuestPaymentStep = useSelector(getIsGuestPaymentStep);
  const token = useSelector(getToken);
  const cart = useSelector(getCart);
  const [tokenId, setTokenId] = useState(null);
  const locale = useLocale();
  const userData = useSelector(getUserData);

  const {error: getUserError} = useGetUserQuery(
    {locale},
    {
      skip: isGuestPaymentStep || !token,
      ...commonDisableCachingParameters,
    },
  );
  const [payAsGuest] = usePayAsGuestMutation();
  const router = useRouter();

  const handleTransactionComplete = props => {
    setTokenId(props?.id);
    router.push('/checkout/4');
  };

  const buildRedirectUrl = transactionUrl => {
    const postUrl = `${process.env.NEXT_PUBLIC_API_URL}/api/v2/storefront/tap_payment_results`;
    return `${transactionUrl}&post_url=${postUrl}`;
  };

  useEffect(() => {
    const handleGuestPayment = async () => {
      if (tokenId && paymentId) {
        const response = await payAsGuest({
          payment_id: paymentId,
          token_id: tokenId,
          redirect_url: `${process.env.NEXT_PUBLIC_FRONT_END_SERVER}/${locale}/complete-payment`,
        });
        if (!response?.error) {
          sendGTMEvent({
            event: 'complete_purchase_trigger',
            ecommerce: {
              discount: cart?.discountAmount,
              coupon: cart?.couponCodes?.[0]?.code,
              value: cart?.total,
              transaction_id: paymentId,
              items: cart.lineItems.map(({name, quantity, price}) => ({
                item_name: name,
                quantity,
                price,
              })),
            },
            ...(token &&
              userData?.userName && {
                name: userData.userName,
                email: userData.userEmail,
                phone_number: userData.userPhone,
              }),
          });
          const transactionUrl =
            response?.data?.data?.attributes?.transaction_url;
          router.push(buildRedirectUrl(transactionUrl));
        }
      }
    };

    handleGuestPayment();
  }, [tokenId, paymentId]);

  if (getUserError) {
    throw new Error('Failed to load user info.');
  }

  return (
    <GoSellElement
      handleTransactionComplete={handleTransactionComplete}
      handlePayment={handlePayment}
      isCreatingPayment={isCreatingPayment}
      isCreatingPaymentWithStoreCredits={isCreatingPaymentWithStoreCredits}
    />
  );
};

export default PaymentModal;
