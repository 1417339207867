'use client';

import {google, phone_label} from '@/assets';
import {Input} from '@heroui/react';
import Image from 'next/image';
import {FaRegEye, FaRegEyeSlash} from 'react-icons/fa';
import React, {useState} from 'react';
import {
  useAssociateCartMutation,
  useLoginMutation,
  useSocialMediaAuthMutation,
} from '@/services';
import {useLocale, useTranslations} from 'next-intl';
import {rules, showErrorToast} from '@/common';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import {useDispatch, useSelector} from 'react-redux';
import {
  openForgetPassword,
  openSignUp,
  setFacebookToken,
  setGoogleToken,
  setIsAddingNewCard,
  setIsGuestPaymentStep,
} from '@/slices';
import {useRouter} from '@/hooks';
import {getCart} from '@/selectors';
import {CustomButton} from '@/components/common';
import {useGoogleSRLogin} from '@/hooks/useGoogleAuth';
import {FacebookAuth} from '@/components/Auth';

const CheckoutUserLoginTab = () => {
  const locale = useLocale();
  const t = useTranslations();
  const router = useRouter();
  const dispatch = useDispatch();
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [loginUser, {isLoading: isLoginLoading}] = useLoginMutation();
  const [associateCart] = useAssociateCartMutation();

  const [facebookLogin, {isLoading: isFacebookLoginLoading}] =
    useSocialMediaAuthMutation();

  const cart = useSelector(getCart);

  const onClosingLogin = () => {
    dispatch(setGoogleToken(null));
    dispatch(setFacebookToken(null));
  };

  const {login} = useGoogleSRLogin(onClosingLogin, cart);

  const validationSchema = Yup.object({
    phoneNumber: rules('loginPhoneNumber', null, t),
    password: rules('loginPassword', null, t),
  });

  const formik = useFormik({
    initialValues: {
      phoneNumber: '',
      password: '',
    },
    validationSchema,
    onSubmit: async values => {
      const response = await loginUser({
        username: `966${values.phoneNumber}`,
        password: values.password,
        grant_type: 'password',
      });
      if (response?.error) {
        showErrorToast(response?.error.error);
      } else {
        await associateCart({
          guest_order_token: cart?.token,
        });
        dispatch(setIsGuestPaymentStep(false));
        dispatch(setIsAddingNewCard(false));
      }
    },
  });

  const togglePasswordVisibility = () =>
    setIsPasswordVisible(!isPasswordVisible);

  const onOpeningSignUpModal = () => {
    dispatch(openSignUp());
  };
  const onHandlingForgetPassword = () => {
    dispatch(openForgetPassword());
  };

  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <div className="flex w-full flex-col">
          <div className="mb-2 mt-2 font-secondary">
            <Input
              type="text"
              variant="underlined"
              name="phoneNumber"
              startContent={
                <div
                  className={`${locale === 'ar' ? 'ml-2' : 'mr-2'} flex w-[7.3rem] items-center gap-3 md:w-[6.3rem]`}>
                  <Image
                    width={35}
                    height={10}
                    src={phone_label}
                    alt="phone label"
                  />
                  <p className="text-[14px] text-persianIndigo">+966</p>
                </div>
              }
              value={formik.values.phoneNumber}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className="w-full text-[16px]"
              classNames={{
                label: '!text-thistle',
                inputWrapper:
                  '!border-thistle hover:border-thistle focus:border-thistle focus:border-[5px] !outline-0 after:bg-persianIndigo',
                input: '!text-persianIndigo',
              }}
            />
            {formik.touched.phoneNumber && formik.errors.phoneNumber ? (
              <div className={`mt-2 font-secondary text-[12px] text-red-500`}>
                {formik.errors.phoneNumber}
              </div>
            ) : null}
          </div>
          <div className="mb-2 w-full font-secondary">
            <Input
              label={t('password')}
              variant="underlined"
              endContent={
                <button
                  className="focus:outline-none"
                  type="button"
                  onClick={togglePasswordVisibility}>
                  {isPasswordVisible ? (
                    <div className="rounded-full bg-thistle p-1">
                      <FaRegEyeSlash className="pointer-events-none text-2xl text-[16px] text-white" />
                    </div>
                  ) : (
                    <div className="rounded-full bg-thistle p-1">
                      <FaRegEye className="pointer-events-none text-2xl text-[16px] text-white" />
                    </div>
                  )}
                </button>
              }
              type={isPasswordVisible ? 'text' : 'password'}
              name="password"
              value={formik.values.password}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              classNames={{
                label: '!text-thistle',
                inputWrapper:
                  '!border-thistle hover:border-thistle focus:border-thistle focus:border-[5px] !outline-0 after:bg-persianIndigo',
                input: '!text-persianIndigo',
              }}
            />
            {formik.touched.password && formik.errors.password ? (
              <div className={`mt-2 font-secondary text-[12px] text-red-500`}>
                {formik.errors.password}
              </div>
            ) : null}
          </div>
          <p
            className="cursor-pointer font-bold text-persianIndigo underline sm:text-[12px] xl:text-[14px]"
            onClick={onHandlingForgetPassword}>
            {t('forget_password')}
          </p>
        </div>
        <CustomButton
          btnStyles="mt-4 w-full h-[52px]"
          isLoading={isLoginLoading || isFacebookLoginLoading}
          value={t('login')}
          btnActionType="submit"
        />
        <div className="mb-[2rem] mt-[1rem] flex items-center justify-center gap-3 sm:mx-[5rem]">
          <div className={`h-0.5 flex-1 bg-thistle`} />
          <p className="font-secondary text-[16px] text-persianIndigo">
            {t('or_sign_in_with')}
          </p>
          <div className={`h-0.5 flex-1 bg-thistle`} />
        </div>
        <div className="flex justify-center gap-4">
          <Image
            src={google}
            width={37}
            height={37}
            alt="google icon"
            className="cursor-pointer"
            onClick={login}
          />
          {/* <FacebookAuth
            handleFacebookLogin={facebookLogin}
            associateCart={associateCart}
            onClosingLogin={onClosingLogin}
          /> */}
        </div>
        <p className="mt-4 text-center font-secondary text-persianIndigo sm:text-[14px] xl:text-[16px]">
          {t('dont_have_an_account')} &ensp;
          <span
            className="cursor-pointer font-secondary font-bold underline"
            onClick={onOpeningSignUpModal}>
            {t('sign_up')}
          </span>
        </p>
      </form>
    </div>
  );
};

export default CheckoutUserLoginTab;
