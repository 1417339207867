'use client';

import {google, phone_label} from '@/assets';
import {rules, showErrorToast} from '@/common';
import {useGoogleSRSignUp} from '@/hooks/useGoogleAuth';
import {getFacebookToken, getGoogleToken} from '@/selectors';
import {getIsSignUpIsOpen} from '@/selectors/modalActionsSelectors';
import {useSignupMutation, useSocialMediaAuthMutation} from '@/services';
import {
  closeSignUp,
  openGeneralOtpModal,
  openSignUp,
  setUserPassowrd,
  setUserPhone,
} from '@/slices';
import {
  DatePicker,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Radio,
  RadioGroup,
} from '@heroui/react';
import {getLocalTimeZone, today} from '@internationalized/date';
import {useFormik} from 'formik';
import moment from 'moment';
import {useLocale, useTranslations} from 'next-intl';
import Image from 'next/image';
import {useMemo, useState} from 'react';
import {FaRegEye, FaRegEyeSlash} from 'react-icons/fa';
import {SlCalender} from 'react-icons/sl';
import {useDispatch, useSelector} from 'react-redux';
import * as Yup from 'yup';
import {CustomButton} from '../common';

const SignUpModal = () => {
  const t = useTranslations();
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const locale = useLocale();
  const [isPasswordConfirmationVisible, setIsPasswordConfirmationVisible] =
    useState(false);
  const [socialMediaSignUp, {isLoading: isSocialMediaSignUpLoading}] =
    useSocialMediaAuthMutation();
  const isSignUpModalOpen = useSelector(getIsSignUpIsOpen);
  const googleToken = useSelector(getGoogleToken);
  const facebookToken = useSelector(getFacebookToken);
  const dispatch = useDispatch();

  const {login} = useGoogleSRSignUp();

  const [signupUser, {isLoading: isSignUpLoading}] = useSignupMutation();

  const validationSchema =
    googleToken || facebookToken
      ? Yup.object({
          phoneNumber: rules('phoneNumber', null, t),
        })
      : Yup.object({
          phoneNumber: rules('phoneNumber', null, t),
          fullName: rules('name', null, t),
          email: rules('email', null, t),
          password: rules('password', null, t),
          passwordConfirmation: rules('passwordConfirmation', null, t),
          gender: rules('gender', null, t),
        });

  const formik = useFormik({
    initialValues: {
      phoneNumber: '',
      fullName: '',
      email: '',
      password: '',
      passwordConfirmation: '',
      birthday: '',
      gender: 'male',
    },
    validationSchema,
    onSubmit: async values => {
      if (googleToken) {
        onHandlingGoogleSignup(values);
      } else if (facebookToken) {
        onHandlingFacebookSignup(values);
      } else {
        onHandlingNormalSignUp(values);
      }
    },
  });

  const onHandlingGoogleSignup = async values => {
    const googleSignUpResponse = await socialMediaSignUp({
      google_token: googleToken,
      phone_number: `966${values.phoneNumber}`,
      grant_type: 'password',
    });
    if (googleSignUpResponse?.error) {
      closingSignUpModal();
      showErrorToast(t('this_phone_number_is_already_registered'));
    } else {
      const {phone_number, is_mobile_verified} = googleSignUpResponse?.data;
      if (phone_number === `966${values.phoneNumber}` && !is_mobile_verified) {
        dispatch(openGeneralOtpModal());
        dispatch(setUserPhone(`966${values.phoneNumber}`));
      }
      closingSignUpModal();
    }
  };

  const onHandlingFacebookSignup = async values => {
    const facebookSignUpResponse = await socialMediaSignUp({
      facebook_token: facebookToken,
      phone_number: `966${values.phoneNumber}`,
      grant_type: 'password',
    });
    if (facebookSignUpResponse?.error) {
      closingSignUpModal();
      showErrorToast(t('this_phone_number_is_already_registered'));
    } else {
      const {phone_number, is_mobile_verified} = facebookSignUpResponse?.data;
      if (phone_number === `966${values.phoneNumber}` && !is_mobile_verified) {
        dispatch(openGeneralOtpModal());
        dispatch(setUserPhone(`966${values.phoneNumber}`));
      }
      closingSignUpModal();
    }
  };

  const onHandlingNormalSignUp = async values => {
    const normalSignUpResponse = await signupUser({
      user: {
        phone_number: `966${values.phoneNumber}`,
        password: values.password,
        birthday: values.birthday,
        email: values.email,
        first_name: values.fullName,
        gender: values.gender,
      },
    });
    if (normalSignUpResponse?.error) {
      const backendErrors = normalSignUpResponse.error.errors;
      formik.setErrors({
        email: backendErrors.email?.[0],
        phoneNumber: backendErrors.phone_number?.[0],
      });
    } else {
      dispatch(setUserPhone(`966${values.phoneNumber}`));
      dispatch(setUserPassowrd(values.password));
    }
  };

  const togglePasswordVisibility = () =>
    setIsPasswordVisible(!isPasswordVisible);
  const togglePasswordConfirmationVisibility = () =>
    setIsPasswordConfirmationVisible(!isPasswordConfirmationVisible);
  const openingSignupModal = () => {
    dispatch(openSignUp());
  };
  const closingSignUpModal = () => {
    dispatch(closeSignUp());
    formik.resetForm();
  };

  const onHandlingSelectingGender = event => {
    formik.setFieldValue('gender', event.target.value);
  };

  const isRegisterLoading = useMemo(
    () => isSignUpLoading || isSocialMediaSignUpLoading,
    [isSignUpLoading, isSocialMediaSignUpLoading],
  );

  return (
    <>
      <div>
        <Modal
          isOpen={isSignUpModalOpen}
          size="2xl"
          onOpenChange={openingSignupModal}
          onClose={closingSignUpModal}
          className="mx-4 p-8 sm:p-12"
          closeButton={
            <CustomButton
              btnType="tertiary"
              value={'X'}
              onClickHandling={closingSignUpModal}
            />
          }
          classNames={{
            closeButton:
              '!text-[22px] !text-persianIndigo hover:!bg-thistle font-main !p-0 !rounded-md mt-4 hidden sm:block',
            wrapper: 'items-center',
          }}>
          <ModalContent>
            {onClose => (
              <>
                <ModalHeader className="justify-center">
                  <span
                    className={`w-[${googleToken || facebookToken ? '30rem' : '16.25rem'}] border-b-4 border-thistle pb-4 text-center font-main text-[${googleToken ? '14px' : '20px'}] font-medium text-persianIndigo sm:text-[${googleToken ? '18px' : '26px'}]`}>
                    {googleToken || facebookToken
                      ? t('enter_your_phone_number_to_receive_your_otp')
                      : t('signup')}
                  </span>
                </ModalHeader>
                <form onSubmit={formik.handleSubmit}>
                  <ModalBody className="!gap-0 !px-0">
                    <div className="mb-4 flex flex-col gap-4 font-secondary sm:flex-row">
                      {!googleToken && !facebookToken && (
                        <div className="w-full sm:w-[50%]">
                          <Input
                            type="text"
                            name="fullName"
                            variant="underlined"
                            label={t('name')}
                            value={formik.values.fullName}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            className="w-full text-[16px]"
                            classNames={{
                              label: '!text-thistle',
                              inputWrapper:
                                '!border-thistle hover:border-thistle focus:border-thistle focus:border-[5px] !outline-0 after:bg-persianIndigo h-8',
                              input: '!text-persianIndigo',
                            }}
                          />
                          {formik.touched.fullName && formik.errors.fullName ? (
                            <div
                              className={`mt-2 font-secondary text-[12px] text-red-500`}>
                              {formik.errors.fullName}
                            </div>
                          ) : null}
                        </div>
                      )}
                      <div
                        className={`w-full sm:w-[${googleToken || facebookToken ? '100%' : '50%'}]`}>
                        <Input
                          type="text"
                          variant="underlined"
                          name="phoneNumber"
                          startContent={
                            <div
                              className={`${locale === 'ar' ? 'ml-2' : 'mr-2'} flex w-[7.3rem] items-center gap-3 md:w-[6.3rem]`}>
                              <Image
                                width={30}
                                height={10}
                                src={phone_label}
                                alt="phone label"
                              />
                              <p className="text-[14px] text-persianIndigo">
                                +966
                              </p>
                            </div>
                          }
                          value={formik.values.phoneNumber}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          className="w-full text-[16px]"
                          classNames={{
                            label: '!text-thistle',
                            inputWrapper:
                              '!border-thistle hover:border-thistle focus:border-thistle focus:border-[5px] !outline-0 after:bg-persianIndigo h-8',
                            input: '!text-persianIndigo',
                          }}
                        />
                        {formik.touched.phoneNumber &&
                        formik.errors.phoneNumber ? (
                          <div
                            className={`mt-2 font-secondary text-[12px] text-red-500`}>
                            {formik.errors.phoneNumber}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    {!googleToken && !facebookToken && (
                      <>
                        <div className="mb-4 flex w-full flex-col gap-4 font-secondary sm:flex-row">
                          <div className="w-full sm:w-[50%]">
                            <Input
                              type="text"
                              name="email"
                              variant="underlined"
                              label={t('email')}
                              value={formik.values.email}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              className="w-full text-[16px]"
                              classNames={{
                                label: '!text-thistle',
                                inputWrapper:
                                  '!border-thistle hover:border-thistle focus:border-thistle focus:border-[5px] !outline-0 after:bg-persianIndigo h-8',
                                input: '!text-persianIndigo',
                              }}
                            />
                            {formik.touched.email && formik.errors.email ? (
                              <div
                                className={`mt-2 font-secondary text-[12px] text-red-500`}>
                                {formik.errors.email}
                              </div>
                            ) : null}
                          </div>
                          <div className="w-full sm:w-[50%]">
                            <DatePicker
                              label={t('birthdate')}
                              variant="underlined"
                              showMonthAndYearPickers
                              className="text-thistle"
                              classNames={{
                                timeInput: '!text-thistle',
                                selectorIcon: '!text-persianIndigo',
                                calendar: 'signup-calander',
                              }}
                              onChange={date => {
                                const parsedDate = new Date(
                                  date.year,
                                  date.month - 1,
                                  date.day,
                                );
                                const formattedDate = moment(parsedDate)
                                  .locale('en')
                                  .format('YYYY-MM-DD');
                                formik.setFieldValue('birthday', formattedDate);
                              }}
                              onBlur={formik.handleBlur}
                              name="birthday"
                              maxValue={today(getLocalTimeZone()).subtract({
                                years: 18,
                              })}
                              selectorIcon={
                                <SlCalender className="mb-4 !text-persianIndigo" />
                              }
                              dateInputClassNames={{
                                innerWrapper: '!pb-0',
                                label: '!text-thistle',
                                inputWrapper:
                                  '!border-thistle hover:border-thistle focus:border-thistle focus:border-[5px] !outline-0 after:bg-persianIndigo h-8',
                                input: '!text-persianIndigo',
                              }}
                            />
                          </div>
                        </div>
                        <div className="mb-4 flex w-full flex-col gap-4 font-secondary sm:flex-row">
                          <div className="w-full sm:w-[50%]">
                            <Input
                              label={t('password')}
                              variant="underlined"
                              endContent={
                                <button
                                  className="focus:outline-none"
                                  type="button"
                                  onClick={togglePasswordVisibility}>
                                  {isPasswordVisible ? (
                                    <div className="rounded-full bg-thistle p-1">
                                      <FaRegEyeSlash className="pointer-events-none text-2xl text-[16px] text-white" />
                                    </div>
                                  ) : (
                                    <div className="rounded-full bg-thistle p-1">
                                      <FaRegEye className="pointer-events-none text-2xl text-[16px] text-white" />
                                    </div>
                                  )}
                                </button>
                              }
                              type={isPasswordVisible ? 'text' : 'password'}
                              name="password"
                              value={formik.values.password}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              classNames={{
                                label: '!text-thistle',
                                inputWrapper:
                                  '!border-thistle hover:border-thistle focus:border-thistle focus:border-[5px] !pt-0 !outline-0 after:bg-persianIndigo h-8',
                                input: '!text-persianIndigo',
                              }}
                            />
                            {formik.touched.password &&
                            formik.errors.password ? (
                              <div
                                className={`mt-2 font-secondary text-[12px] text-red-500`}>
                                {formik.errors.password}
                              </div>
                            ) : null}
                          </div>
                          <div className="w-full sm:w-[50%]">
                            <Input
                              label={t('confirm_password')}
                              variant="underlined"
                              endContent={
                                <button
                                  className="focus:outline-none"
                                  type="button"
                                  onClick={
                                    togglePasswordConfirmationVisibility
                                  }>
                                  {isPasswordConfirmationVisible ? (
                                    <div className="rounded-full bg-thistle p-1">
                                      <FaRegEyeSlash className="pointer-events-none text-2xl text-[16px] text-white" />
                                    </div>
                                  ) : (
                                    <div className="rounded-full bg-thistle p-1">
                                      <FaRegEye className="pointer-events-none text-2xl text-[16px] text-white" />
                                    </div>
                                  )}
                                </button>
                              }
                              type={
                                isPasswordConfirmationVisible
                                  ? 'text'
                                  : 'password'
                              }
                              name="passwordConfirmation"
                              value={formik.values.passwordConfirmation}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              classNames={{
                                label: '!text-thistle z-[-1]',
                                inputWrapper:
                                  '!border-thistle hover:border-thistle focus:border-thistle focus:border-[5px] !pt-0 !outline-0 after:bg-persianIndigo h-8',
                                input: '!text-persianIndigo',
                              }}
                            />
                            {formik.touched.passwordConfirmation &&
                            formik.errors.passwordConfirmation ? (
                              <div
                                className={`mt-2 font-secondary text-[12px] text-red-500`}>
                                {formik.errors.passwordConfirmation}
                              </div>
                            ) : null}
                          </div>
                        </div>

                        <div className="mb-4">
                          <RadioGroup
                            defaultValue="male"
                            onChange={onHandlingSelectingGender}
                            className="!flex-row !justify-between"
                            classNames={{label: 'text-thistle'}}
                            label={t('select_your_gender')}
                            orientation="horizontal"
                            color="secondary">
                            <Radio value="male">
                              {' '}
                              <span className="text-persianIndigo">
                                {t('male')}
                              </span>
                            </Radio>
                            <Radio value="female">
                              <span className="text-persianIndigo">
                                {t('female')}
                              </span>
                            </Radio>
                          </RadioGroup>
                          {formik.touched.gender && formik.errors.gender ? (
                            <div
                              className={`mt-2 font-secondary text-[12px] text-red-500`}>
                              {formik.errors.gender}
                            </div>
                          ) : null}
                        </div>
                      </>
                    )}
                  </ModalBody>
                  <ModalFooter className="!p-0">
                    <CustomButton
                      isLoading={isRegisterLoading}
                      value={t('register')}
                      btnType="primary"
                      btnStyles={'w-full'}
                      btnActionType="submit"
                    />
                  </ModalFooter>
                </form>
                {!googleToken && !facebookToken && (
                  <>
                    <div className="my-[2rem] flex items-center justify-center gap-3 sm:mx-[5rem]">
                      <div className={`h-0.5 flex-1 bg-thistle`} />
                      <p className="font-secondary text-[16px] text-persianIndigo">
                        {t('or_sign_up_with')}
                      </p>
                      <div className={`h-0.5 flex-1 bg-thistle`} />
                    </div>
                    <div className="flex justify-center gap-4">
                      <Image
                        src={google}
                        width={37}
                        height={37}
                        alt="google icon"
                        className="cursor-pointer"
                        onClick={login}
                      />
                      {/* <FacebookAuth /> */}
                    </div>
                  </>
                )}
              </>
            )}
          </ModalContent>
        </Modal>
      </div>
    </>
  );
};

export default SignUpModal;
