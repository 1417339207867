'use client';

import {
  commonDisableCachingParameters,
  convertEnglishNumbersToArabic,
  getLocalized12HourFormat,
  showErrorToast,
} from '@/common';
import {OtpModal} from '@/components/Auth';
import {CustomButton} from '@/components/common';
import {useRouter} from '@/hooks';
import {
  getCart,
  getIsGuestPaymentStep,
  getIsOTPModalOpen,
  getNearestStoreData,
  getToken,
} from '@/selectors';
import {
  useGetUserQuery,
  useLazyGetAllDeliverySlotsQuery,
  useLazyGetOrderPreparationAbilityQuery,
  useNextCheckoutStepMutation,
  useUpdateCheckoutMutation,
  useVerifyUserOtpMutation,
} from '@/services';
import {openOtpModal} from '@/slices';
import {Button, Calendar, Input} from '@heroui/react';
import {getLocalTimeZone, parseDate, today} from '@internationalized/date';
import moment from 'moment';
import {useLocale, useTranslations} from 'next-intl';
import {useEffect, useMemo, useState} from 'react';
import {MdKeyboardArrowDown} from 'react-icons/md';
import {useDispatch, useSelector} from 'react-redux';

const PickupTime = () => {
  const t = useTranslations();
  const locale = useLocale();
  const router = useRouter();
  const store = useSelector(getNearestStoreData);
  const cart = useSelector(getCart);
  const now = moment().locale('en');
  const formattedDate = now.format('YYYY-MM-DD');
  moment.locale('en');

  const [showCalendar, setShowCalendar] = useState(false);
  const [showTimeSlots, setShowTimeSlots] = useState(false);
  const [value, setValue] = useState(parseDate(formattedDate));
  const [inputDate, setInputDate] = useState('');
  const [selectedSlotId, setSelectedSlotId] = useState(null);
  const [startSlot, setStartSlot] = useState(null);
  const [endSlot, setEndSlot] = useState(null);
  const [preparationError, setPreparationError] = useState(null);
  const [currentPickupType, setCurrentPickupType] = useState(1);
  const [currentFormattedTime, setCurrentFormattedTime] = useState('');
  const isGuestPayment = useSelector(getIsGuestPaymentStep);
  const token = useSelector(getToken);
  const dispatch = useDispatch();
  const isOtpModalOpen = useSelector(getIsOTPModalOpen);
  const isInstant = useMemo(() => currentPickupType === 1, [currentPickupType]);

  const readyToProceed = useMemo(
    () => (isInstant ? false : !(inputDate && selectedSlotId)),
    [inputDate, selectedSlotId, isInstant],
  );

  const [
    getDeliverySlots,
    {data: deliverySlots, error: getDeliverySlotsError},
  ] = useLazyGetAllDeliverySlotsQuery();
  const [requestUserOtp] = useRequestUserOtpMutation();
  const [verifyUserOtp] = useVerifyUserOtpMutation();
  const [checkForAbilityToPay] = useLazyGetOrderPreparationAbilityQuery();
  const [goToNextCheckOutStep] = useNextCheckoutStepMutation();

  const [updateShippingTime, {isLoading: isUpdatingShippingTime}] =
    useUpdateCheckoutMutation();

  const {data: user, error: getUserError} = useGetUserQuery(
    {locale},
    {...commonDisableCachingParameters, skip: isGuestPayment || !token},
  );

  const [updateShippingAddress, {isLoading: isUpdatingShippingAddress}] =
    useUpdateCheckoutMutation();

  const handleDateChange = async newValue => {
    setValue(newValue);

    const date = moment(
      `${newValue.year}-${newValue.month}-${newValue.day}`,
      'YYYY-M-D',
    ).locale('en');
    const formatter = new Intl.DateTimeFormat(locale, {
      month: 'long',
      weekday: 'long',
      day: 'numeric',
    });
    const dateInput = formatter.format(date);

    const scheduleDate = moment(
      `${newValue.year}-${newValue.month}-${newValue.day}`,
      'YYYY-M-D',
    )
      .locale('en')
      .format('MMM Do YYYY');

    const response = await updateShippingTime({
      order: {
        schedule_time: isInstant ? null : scheduleDate,
      },
    });

    await getDeliverySlots({
      order_id: response?.data?.data?.attributes?.number,
      working_day_id:
        response?.data?.data?.relationships?.working_day?.data?.id,
    });

    const formattedDate =
      locale === 'ar'
        ? dateInput.replace(/\d/g, d => '٠١٢٣٤٥٦٧٨٩'[d])
        : dateInput;

    setInputDate(formattedDate);
    setShowCalendar(false);
    setSelectedSlotId(null);
    setShowTimeSlots(false);
  };

  const handleSelectSlot = item => {
    setSelectedSlotId(item.id);
    setStartSlot(getLocalized12HourFormat(item.attributes.start_time, locale));
    setEndSlot(getLocalized12HourFormat(item.attributes.end_time, locale));
    setShowTimeSlots(false);
  };

  const handleUpdateShippingTime = async id => {
    const response = await updateShippingTime({
      order: {
        delivery_slot_id: isInstant ? '' : id,
        ...(isInstant && {schedule_time: ''}),
      },
    });
    if (response?.error) {
      showErrorToast(response.error.error);
    } else {
      const response = await checkForAbilityToPay();
      if (response?.error) {
        const data = response?.error?.error;
        setPreparationError(
          t(`preparation_error`, {
            productName: data?.product_name,
            productPrepHours: data?.preparation_time,
          }),
        );
      } else {
        router.push('/checkout/4');
        await goToNextCheckOutStep();
      }
    }
  };

  const onSwitchingToInstant = () => {
    setCurrentPickupType(1);
    setPreparationError(null);
  };
  const onSwitchingToScheduled = () => {
    setCurrentPickupType(2);
    setPreparationError(null);
  };

  const onTogglingCalander = () => {
    setShowCalendar(!showCalendar);
    setPreparationError(null);
  };
  const onToggleTimeSlots = () => {
    setShowTimeSlots(!showTimeSlots);
    setPreparationError(null);
  };

  const addHoursToTime = hours => {
    const time = moment().add(hours, 'hours');
    return time.format('h:mm A');
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      const updatedTime = addHoursToTime(cart?.maxPreparationTime);
      setCurrentFormattedTime(updatedTime);
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  if (getDeliverySlotsError) {
    throw new Error('Failed to load delivery slots.');
  }

  const handleProceedToPayment = async () => {
    const response = await updateShippingAddress({
      order: {
        ship_address_attributes: {
          firstname: 'N/A',
          lastname: 'N/A',
          phone: user?.data?.attributes?.phone_number,
          country_iso: 'SA',
          city: 'SACity',
          address1: 'N/A',
        },
        bill_address_attributes: {
          firstname: 'N/A',
          lastname: 'N/A',
          phone: user?.data?.attributes?.phone_number,
          country_iso: 'SA',
          city: 'SACity',
          address1: 'N/A',
        },
        ...(isGuestPayment && {email: 'guest@example.com'}),
      },
    });
    if (!response.error) {
      onHandlingUnVerifiedUser();
    } else {
      showErrorToast(t('some_thing_went_wrong'));
    }
  };
  const onHandlingUnVerifiedUser = async () => {
    if (!user?.data.attributes.is_mobile_verified && token) {
      await requestUserOtp({username: user?.data?.attributes?.phone_number});
      dispatch(openOtpModal());
    } else {
      onNavigatingToPaymentStep();
    }
  };

  const onNavigatingToPaymentStep = () => {
    handleUpdateShippingTime(selectedSlotId);
  };

  if (getUserError) {
    throw new Error('Failed to load user info.');
  }

  return (
    <div className="py-5">
      <div className="mb-[25px] flex w-full">
        <div className="w-[50%]">
          <Button
            radius="none"
            onPress={onSwitchingToInstant}
            className={`${isInstant ? 'bg-persianIndigo' : 'bg-thistle'} ${locale === 'ar' ? 'rounded-r-[5px]' : 'rounded-l-[5px]'} h-[3.75rem] w-full font-main text-[20px] text-white`}>
            {t('instant')}
          </Button>
        </div>
        <div className="w-[50%]">
          <Button
            radius="none"
            onPress={onSwitchingToScheduled}
            className={`${!isInstant ? 'bg-persianIndigo' : 'bg-thistle'} ${locale === 'ar' ? 'rounded-l-[5px]' : 'rounded-r-[5px]'} h-[3.75rem] w-full font-main text-[20px] text-white`}>
            {t('scheduled')}
          </Button>
        </div>
      </div>
      <h2 className="font-main text-[16px] font-medium text-persianIndigo xl:text-[1.1vw]">
        {t('pick_up_time')}
      </h2>
      {!isInstant ? (
        <>
          <p className="mt-2 font-secondary text-[14px] font-normal text-persianIndigo xl:text-[1vw]">
            {t('please_select_your_preferred_date_pick_up_time_slot')}
          </p>
          <p className="font-secondary text-[14px] font-normal text-persianIndigo xl:text-[1vw]">
            {t('location')}:
          </p>
          <p className="mt-2 font-secondary text-[12px] font-normal text-persianIndigo xl:text-[0.9vw]">
            {store?.storeAddress}
          </p>
          <div className="my-4">
            <Input
              type="text"
              className="mb-4"
              classNames={{
                innerWrapper: 'cursor-pointer',
                inputWrapper:
                  'border-thistle after:bg-transparent transition-colors data-[hover=true]:border-thistle group-data-[focus=true]:border-[#D6BDD0]',
                input:
                  '!text-persianIndigo cursor-pointer text-[16px] xl:text-[14px]  placeholder:text-thistle placeholder:text-[16px] xl:placeholder:text-[14px]',
              }}
              variant="underlined"
              placeholder={t('select_date')}
              endContent={
                <MdKeyboardArrowDown
                  className="text-[30px] text-persianIndigo xl:text-[20px]"
                  onClick={onTogglingCalander}
                />
              }
              readOnly
              value={inputDate}
              onClick={onTogglingCalander}
            />
            {showCalendar ? (
              <Calendar
                value={value}
                onChange={handleDateChange}
                defaultValue={today(getLocalTimeZone())}
                minValue={today(getLocalTimeZone())}
                calendarWidth="100%"
                weekdayStyle="short"
                color="secondary"
                classNames={{
                  cell: 'p-[0.5rem]',
                  gridHeaderCell: 'p-[1.5rem]',
                  content: 'bg-antiFlashWhite',
                  headerWrapper: 'bg-antiFlashWhite',
                  gridHeader: 'bg-antiFlashWhite',
                }}
              />
            ) : null}
            <Input
              classNames={{
                innerWrapper: 'cursor-pointer',
                inputWrapper:
                  'border-thistle after:bg-transparent transition-colors data-[hover=true]:border-thistle group-data-[focus=true]:border-[#D6BDD0]',
                input:
                  '!text-persianIndigo cursor-pointer text-[16px] xl:text-[14px]  placeholder:text-thistle placeholder:text-[16px] xl:placeholder:text-[14px]',
              }}
              variant="underlined"
              className="mb-4"
              placeholder={t('select_time')}
              endContent={
                <MdKeyboardArrowDown
                  className="text-[30px] text-persianIndigo xl:text-[20px]"
                  onClick={onToggleTimeSlots}
                />
              }
              readOnly
              value={
                selectedSlotId
                  ? `${convertEnglishNumbersToArabic(startSlot, locale)} - ${convertEnglishNumbersToArabic(endSlot, locale)}`
                  : ''
              }
              onClick={onToggleTimeSlots}
            />
            {showTimeSlots &&
              (deliverySlots?.data?.length > 0 ? (
                <div className="mt-4">
                  {deliverySlots?.data?.map((slot, index) => {
                    const isAvailable = slot?.attributes?.available;

                    return (
                      <div
                        key={index}
                        className={`border-b-1 border-thistle bg-antiFlashWhite px-[1rem] py-[1rem] ${
                          isAvailable
                            ? 'cursor-pointer text-persianIndigo'
                            : 'cursor-not-allowed text-silverChalice'
                        }`}
                        onClick={() => isAvailable && handleSelectSlot(slot)}>
                        {convertEnglishNumbersToArabic(
                          getLocalized12HourFormat(
                            slot.attributes.start_time,
                            locale,
                          ),
                          locale,
                        )}{' '}
                        -{' '}
                        {convertEnglishNumbersToArabic(
                          getLocalized12HourFormat(
                            slot.attributes.end_time,
                            locale,
                          ),
                          locale,
                        )}
                      </div>
                    );
                  })}
                </div>
              ) : (
                <div className="mt-4 cursor-pointer border-b-1 border-thistle bg-antiFlashWhite px-[1rem] py-[1rem] text-persianIndigo">
                  {t('no_available_time_slots')}
                </div>
              ))}
          </div>
          {preparationError && (
            <div className="my-2 font-secondary text-[12px] text-pink">
              <p className="font-semiBold text-[14px]">
                {t('unable_to_place_order_at_the_moment')}
              </p>
              <p>{preparationError}</p>
            </div>
          )}
        </>
      ) : (
        <>
          {Number(cart?.maxPreparationTime) > 0 && (
            <p className="mt-2 font-secondary text-[14px] text-persianIndigo xl:text-[1vw]">
              {t('please_know_that_the_preparation_time_of_your_order_is')}{' '}
              {`${convertEnglishNumbersToArabic(cart?.maxPreparationTime, locale)} `}{' '}
              {Number(cart?.maxPreparationTime) > 1 ? t('hours') : t('hour')}
            </p>
          )}
          <p className="font-secondary text-[14px] text-persianIndigo xl:text-[1vw]">
            {t('pick_up_time')}:{' '}
            {convertEnglishNumbersToArabic(currentFormattedTime, locale)}{' '}
          </p>
        </>
      )}
      <div
        className="mt-2 font-secondary text-[12px] text-pink"
        dangerouslySetInnerHTML={{__html: preparationError}}></div>
      <CustomButton
        btnStyles="w-full mt-5 h-[52px]"
        onClickHandling={handleProceedToPayment}
        isLoading={isUpdatingShippingTime || isUpdatingShippingAddress}
        value={t('proceed_to_payment')}
        isDisabled={readyToProceed}
      />
      <OtpModal
        isOpen={isOtpModalOpen}
        userPhoneNumber={user?.data?.attributes?.phone_number}
        onRequestOtp={() =>
          requestUserOtp({username: user?.data?.attributes?.phone_number})
        }
        onVerifyOtp={otp =>
          verifyUserOtp({
            username: user?.data?.attributes?.phone_number,
            otp_code: otp,
          })
        }
        onVerificationSuccess={onNavigatingToPaymentStep}
      />
    </div>
  );
};

export default PickupTime;
