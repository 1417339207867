'use client';

import {Tab, Tabs} from '@heroui/react';
import {useTranslations} from 'next-intl';
import React, {useState} from 'react';
import {CheckoutUserLoginTab, GuestUserLoginTab} from '.';

const ChooseLogin = () => {
  const t = useTranslations();
  const [selectedTab, setSelectedTab] = useState('user');
  return (
    <div className="mt-[2.5rem] sm:mt-0">
      <Tabs
        aria-label="Options"
        variant="underlined"
        selectedKey={selectedTab}
        fullWidth
        onSelectionChange={setSelectedTab}
        classNames={{
          tabContent:
            'xl:text-[14px] xl:text-[18px] group-data-[selected=true]:text-persianIndigo font-main font-normal text-thistle',
          tabList: 'gap-0 border-b-1 border-thistle pb-[5px]',
          cursor: 'w-[102%] bottom-[-5px] !bg-persianIndigo',
        }}>
        <Tab key="user" title={t('sign_in')}>
          <CheckoutUserLoginTab />
        </Tab>
        <Tab key="guest" title={t('continue_as_a_guest')}>
          <GuestUserLoginTab />
        </Tab>
      </Tabs>
    </div>
  );
};

export default ChooseLogin;
