'use client';

import {google, phone_label} from '@/assets';
import {rules, showErrorToast} from '@/common';
import {useGoogleSRLogin} from '@/hooks/useGoogleAuth';
import {getCart, getIsLogInOpen} from '@/selectors';
import {
  useAssociateCartMutation,
  useLoginMutation,
  useSocialMediaAuthMutation,
} from '@/services';
import {
  closeLogIn,
  openForgetPassword,
  openLogIn,
  openSignUp,
  setFacebookToken,
  setGoogleToken,
  setIsAddingNewCard,
  setIsGuestPaymentStep,
} from '@/slices';
import {
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from '@heroui/react';
import {useFormik} from 'formik';
import {useLocale, useTranslations} from 'next-intl';
import Image from 'next/image';
import {useState} from 'react';
import {FaRegEye, FaRegEyeSlash} from 'react-icons/fa';
import {useDispatch, useSelector} from 'react-redux';
import * as Yup from 'yup';
import {CustomButton} from '../common';

const LogInModal = () => {
  const t = useTranslations();
  const cart = useSelector(getCart);
  const locale = useLocale();
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [loginUser, {isLoading: isLoginLoading}] = useLoginMutation();
  const [associateCart] = useAssociateCartMutation();
  const isLogInOpen = useSelector(getIsLogInOpen);
  const dispatch = useDispatch();

  const [facebookLogin, {isLoading: isFacebookLoginLoading}] =
    useSocialMediaAuthMutation();

  const validationSchema = Yup.object({
    phoneNumber: rules('loginPhoneNumber', null, t),
    password: rules('loginPassword', null, t),
  });

  const formik = useFormik({
    initialValues: {
      phoneNumber: '',
      password: '',
    },
    validationSchema,
    onSubmit: async values => {
      const response = await loginUser({
        username: `966${values.phoneNumber}`,
        password: values.password,
        grant_type: 'password',
      });
      if (response?.error) {
        showErrorToast(response?.error.error);
      } else {
        dispatch(setIsGuestPaymentStep(false));
        dispatch(setIsAddingNewCard(false));
        onClosingLogin();
        await associateCart({
          guest_order_token: cart?.token,
        });
      }
    },
  });

  const togglePasswordVisibility = () =>
    setIsPasswordVisible(!isPasswordVisible);

  const onOpeningLogIn = () => {
    dispatch(openLogIn());
    dispatch(setGoogleToken(null));
    dispatch(setFacebookToken(null));
  };
  const onClosingLogin = () => {
    dispatch(closeLogIn());
    dispatch(setGoogleToken(null));
    dispatch(setFacebookToken(null));
  };

  const onHandlingForgetPassword = () => {
    onClosingLogin();
    dispatch(openForgetPassword());
  };

  const handleOpeningSignUpModal = () => {
    onClosingLogin();
    dispatch(openSignUp());
  };

  const {login} = useGoogleSRLogin(onClosingLogin, cart);

  return (
    <>
      <div>
        <Modal
          isOpen={isLogInOpen}
          size="2xl"
          className="mx-4 p-8 sm:p-12"
          onOpenChange={onOpeningLogIn}
          onClose={onClosingLogin}
          closeButton={
            <CustomButton
              btnType="tertiary"
              value={'X'}
              onClickHandling={onClosingLogin}
            />
          }
          classNames={{
            closeButton:
              '!text-[22px] !text-persianIndigo hover:!bg-thistle font-main !p-0 !rounded-md mt-4 hidden sm:block',
            wrapper: 'items-center',
          }}>
          <ModalContent>
            {onClose => (
              <>
                <ModalHeader className="justify-center">
                  <span className="w-[260px] border-b-4 border-thistle pb-[10px] text-center font-main text-[20px] font-medium text-persianIndigo sm:text-[26px]">
                    {t('login')}
                  </span>
                </ModalHeader>
                <form onSubmit={formik.handleSubmit}>
                  <ModalBody className="!px-0">
                    <p className="font-semiBold text-center font-secondary text-persianIndigo">
                      {t('you_need_to_login_to_view_your_profile')}
                    </p>
                    <div className="flex w-full flex-col">
                      <div className="mb-3 font-secondary">
                        <Input
                          type="text"
                          variant="underlined"
                          name="phoneNumber"
                          startContent={
                            <div
                              className={`${locale === 'ar' ? 'ml-2' : 'mr-2'} flex w-[7.3rem] items-center gap-3 md:w-[6.3rem]`}>
                              <Image
                                width={35}
                                height={10}
                                src={phone_label}
                                alt="phone label"
                              />
                              <p className="text-[14px] text-persianIndigo">
                                +966
                              </p>
                            </div>
                          }
                          value={formik.values.phoneNumber}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          className="w-full text-[16px]"
                          classNames={{
                            inputWrapper:
                              '!border-thistle hover:border-thistle focus:border-thistle focus:border-[5px] !outline-0 after:bg-persianIndigo',
                            input: '!text-persianIndigo',
                          }}
                        />
                        {formik.touched.phoneNumber &&
                        formik.errors.phoneNumber ? (
                          <div
                            className={`mt-2 font-secondary text-[12px] text-red-500`}>
                            {formik.errors.phoneNumber}
                          </div>
                        ) : null}
                      </div>
                      <div className="mb-3 w-full font-secondary">
                        <Input
                          label={t('password')}
                          variant="underlined"
                          endContent={
                            <button
                              className="focus:outline-none"
                              type="button"
                              onClick={togglePasswordVisibility}>
                              {isPasswordVisible ? (
                                <div className="rounded-full bg-thistle p-1">
                                  <FaRegEyeSlash className="pointer-events-none text-2xl text-[16px] text-white" />
                                </div>
                              ) : (
                                <div className="rounded-full bg-thistle p-1">
                                  <FaRegEye className="pointer-events-none text-2xl text-[16px] text-white" />
                                </div>
                              )}
                            </button>
                          }
                          type={isPasswordVisible ? 'text' : 'password'}
                          name="password"
                          value={formik.values.password}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          classNames={{
                            label: '!text-thistle',
                            inputWrapper:
                              '!border-thistle hover:border-thistle focus:border-thistle focus:border-[5px] !outline-0 after:bg-persianIndigo',
                            input: '!text-persianIndigo',
                          }}
                        />
                        {formik.touched.password && formik.errors.password ? (
                          <div
                            className={`mt-2 font-secondary text-[12px] text-red-500`}>
                            {formik.errors.password}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <p
                      className="cursor-pointer font-bold text-persianIndigo underline sm:text-[12px] xl:text-[14px]"
                      onClick={onHandlingForgetPassword}>
                      {t('forget_password')}
                    </p>
                    <p className="text-center font-secondary text-persianIndigo">
                      {t('dont_have_an_account')} &ensp;
                      <span
                        className="cursor-pointer font-secondary font-bold text-persianIndigo underline"
                        onClick={handleOpeningSignUpModal}>
                        {t('signup')}
                      </span>
                    </p>
                  </ModalBody>
                  <ModalFooter className="!px-0">
                    <CustomButton
                      isLoading={isLoginLoading || isFacebookLoginLoading}
                      btnActionType="submit"
                      btnStyles={'w-full'}
                      value={t('login')}
                    />
                  </ModalFooter>
                </form>
                <div className="mb-[2rem] mt-[1rem] flex items-center justify-center gap-3 sm:mx-[5rem]">
                  <div className={`h-0.5 flex-1 bg-thistle`} />
                  <p className="font-secondary text-[16px] text-persianIndigo">
                    {t('or_sign_in_with')}
                  </p>
                  <div className={`h-0.5 flex-1 bg-thistle`} />
                </div>
                <div className="flex justify-center gap-4">
                  <Image
                    src={google}
                    width={37}
                    height={37}
                    alt="google icon"
                    className="cursor-pointer"
                    onClick={login}
                  />
                  {/* <FacebookAuth
                    handleFacebookLogin={facebookLogin}
                    associateCart={associateCart}
                    onClosingLogin={onClosingLogin}
                    cart={cart}
                  /> */}
                </div>
              </>
            )}
          </ModalContent>
        </Modal>
      </div>
    </>
  );
};

export default LogInModal;
