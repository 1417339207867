import {convertEnglishNumbersToArabic} from '@/common';
import {useLocale} from 'next-intl';
import React, {Fragment} from 'react';
import {MdPayment} from 'react-icons/md';
import {BsPersonCheck} from 'react-icons/bs';
import {LiaAddressCard} from 'react-icons/lia';
import {BiTime} from 'react-icons/bi';

const pickUpSteps = [
  {id: 1, icon: <BsPersonCheck />},
  {id: 2, icon: <BiTime />},
  {id: 3, icon: <MdPayment />},
];

const deliverySteps = [
  {id: 1, icon: <BsPersonCheck />},
  {id: 2, icon: <LiaAddressCard />},
  {id: 3, icon: <BiTime />},
  {id: 4, icon: <MdPayment />},
];

const StepperSteps = ({steps, currentStep}) => (
  <div className="mx-4 mb-4 flex items-center justify-center sm:mx-0">
    {steps.map((step, index) => (
      <Fragment key={step.id}>
        <div
          className={`${
            +currentStep >= +step.id
              ? 'bg-thistle text-white'
              : 'bg-antiFlashWhite text-thistle'
          } flex h-[30px] w-[30px] items-center justify-center rounded-full font-main sm:text-[16px] xl:h-[60px] xl:w-[60px] xl:text-[30px]`}>
          {step.icon}
        </div>
        {index < steps.length - 1 && (
          <div
            className={`h-0.5 flex-1 ${+currentStep > +step.id ? 'bg-thistle' : 'bg-antiFlashWhite'}`}></div>
        )}
      </Fragment>
    ))}
  </div>
);

const Stepper = ({currentStep, deliveryMethodId}) => {
  const steps = deliveryMethodId === 1 ? deliverySteps : pickUpSteps;
  return <StepperSteps steps={steps} currentStep={currentStep} />;
};

export default Stepper;
