'use client';

import {
  commonDisableCachingParameters,
  convertEnglishNumbersToArabic,
  showErrorToast,
} from '@/common';
import {getIsGuestPaymentStep, getToken} from '@/selectors';
import {useCreateReviewMutation, useGetUserQuery} from '@/services';
import {useRouter} from '@/hooks';
import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from '@heroui/react';
import {useLocale, useTranslations} from 'next-intl';
import {useState} from 'react';
import {useSelector} from 'react-redux';
import {CustomButton} from '../../common';

const RatingModal = ({isRatingModalOpen, setIsRatingModalOpen}) => {
  const t = useTranslations();
  const locale = useLocale();
  const router = useRouter();
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [rate, setRate] = useState(null);
  const [comment, setComment] = useState('');
  const isGuestPaymentStep = useSelector(getIsGuestPaymentStep);
  const token = useSelector(getToken);

  const [createReview, {isLoading}] = useCreateReviewMutation();
  const {data: user, error: getUserError} = useGetUserQuery(
    {locale},
    {
      skip: isGuestPaymentStep || !token,
      ...commonDisableCachingParameters,
    },
  );

  const handleCircleClick = index => {
    setSelectedIndex(index);
    setRate(index + 1);
  };

  const handleRating = async () => {
    const response = await createReview({
      review: {
        rating: rate,
        comment,
        reviewable_id: 1,
        reviewable_type: 'Spree::Store',
        user_id: user?.data?.id,
      },
    });
    setIsRatingModalOpen(false);
    if (!response.error) {
      router.push('/');
    } else {
      showErrorToast(t('rating_failed_please_try_again'));
    }
  };

  if (getUserError) {
    throw new Error('Failed to load user info.');
  }

  return (
    <Modal
      isOpen={isRatingModalOpen}
      size="xl"
      classNames={{
        closeButton: 'hidden',
        wrapper: 'items-center',
      }}
      onClose={() => setIsRatingModalOpen(false)}
      className="mx-4 rounded-[2rem]">
      <ModalContent>
        <ModalHeader className="flex items-center justify-center sm:justify-between">
          <p className="font-main text-[16px] font-medium text-persianIndigo xl:text-[1.5vw]">
            {t('rate_your_shopping_experience')}!
          </p>
          <CustomButton
            btnType="tertiary"
            value={'X'}
            btnStyles="text-[1.375rem] text-persianIndigo !hover:!bg-none hidden sm:block"
            onClickHandling={() => setIsRatingModalOpen(false)}
          />
        </ModalHeader>
        <ModalBody className="mx-auto flex flex-col items-start">
          <div className="my-6 flex flex-col items-center">
            <div className="flex gap-4">
              {Array.from({length: 5}).map((_, index) => (
                <div key={index} className="flex flex-col items-center">
                  <div
                    className={`flex h-[3rem] w-[3rem] cursor-pointer items-center justify-center rounded-full border-1 border-thistle font-secondary text-[14px] font-normal text-persianIndigo sm:h-[4rem] sm:w-[4rem] xl:text-[1.1vw] ${
                      selectedIndex === index ? 'bg-royalPurple text-white' : ''
                    }`}
                    onClick={() => handleCircleClick(index)}>
                    {convertEnglishNumbersToArabic(index + 1, locale)}
                  </div>
                  {index === 0 && (
                    <span className="mt-1 font-secondary text-[14px] font-normal text-royalPurple xl:text-[0.9vw]">
                      {t('bad')}
                    </span>
                  )}
                  {index === 4 && (
                    <span className="mt-1 font-secondary text-[14px] font-normal text-royalPurple xl:text-[0.9vw]">
                      {t('excellent')}
                    </span>
                  )}
                </div>
              ))}
            </div>
          </div>
        </ModalBody>
        <ModalFooter className="flex flex-col">
          <p className="font-secondary text-[14px] font-normal text-persianIndigo xl:text-[1.1vw]">
            {t('write_comment')}
          </p>
          <Input
            endContent={
              <Button
                className="my-2 rounded-md bg-royalPurple px-[1.9rem] py-[0.5rem] font-main text-[14px] text-white xl:text-[1vw]"
                type="button"
                onPress={handleRating}
                isLoading={isLoading}>
                {t('send')}
              </Button>
            }
            type={'text'}
            onChange={e => setComment(e.target.value)}
            className="mb-8 self-center"
            classNames={{
              inputWrapper: [
                'border-[0.125px]',
                '!border-thistle',
                '!bg-white',
                '!pr-[0.5rem]',
                'h-[3.4rem]',
                'rounded-[0.6rem]',
              ],
            }}
            size="md"
          />
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default RatingModal;
