'use client';

import {thirdFlower} from '@/assets';
import {
  capitalizeEachWord,
  commonDisableCachingParameters,
  convertEnglishNumbersToArabic,
  showErrorToast,
} from '@/common';
import {useApplyCartActions, useRouter} from '@/hooks';
import {
  getCardAmount,
  getCart,
  getDeliveryMethodId,
  getGuestCredentials,
  getIsApplePayment,
  getIsGuestPaymentStep,
  getIsWalletPaymentChosen,
  getNearestStoreData,
  getToken,
} from '@/selectors';
import {
  useAddCouponCodeMutation,
  useGetAddOnsQuery,
  useGetAllCategoriesQuery,
  useGetUserWithAddressesQuery,
  useRemoveCouponCodeMutation,
  useUpdateCheckoutMutation,
} from '@/services';
import {
  openLogIn,
  setCardAmount,
  setIsAddingNewCard,
  setIsWalletPaymentChosen,
} from '@/slices';
import {Checkbox, Input} from '@heroui/react';
import {sendGTMEvent} from '@next/third-parties/google';
import {useFormik} from 'formik';
import {useLocale, useTranslations} from 'next-intl';
import Image from 'next/image';
import {useParams} from 'next/navigation';
import {useEffect, useMemo, useState} from 'react';
import toast from 'react-hot-toast';
import {FaMinus, FaPlus} from 'react-icons/fa';
import {IoIosArrowDown, IoIosArrowForward} from 'react-icons/io';
import {useDispatch, useSelector} from 'react-redux';
import {useMediaQuery} from 'react-responsive';
import {
  AddressesList,
  CheckoutGiftCard,
  ChooseLogin,
  CreditCardsCarousel,
  PickupTime,
  RecommendedAddOns,
  ShippingTime,
  Stepper,
} from '.';
import {CustomButton} from '../common';
import {ShippingAddressForm} from '../Receiver';

const CheckoutStep = () => {
  const {step} = useParams();
  const t = useTranslations();
  const dispatch = useDispatch();
  const deliveryMethodId = useSelector(getDeliveryMethodId);
  const isGuestPaymentStep = useSelector(getIsGuestPaymentStep);
  const guestCredentials = useSelector(getGuestCredentials);
  const token = useSelector(getToken);
  const [updateOrder] = useUpdateCheckoutMutation();

  const [selectedAddress, setSelectedAddress] = useState(null);

  useEffect(() => {
    if (+step === 1) {
      dispatch(setIsWalletPaymentChosen(false));
    }
    if (+step === 2) {
      dispatch(setIsAddingNewCard(false));
    }
    if (+step === 2 && guestCredentials?.isDataValid && !token) {
      updateOrder({
        order: {
          guest_name: guestCredentials?.guestFullName,
          phone_number: `966${guestCredentials?.guestPhoneNumber}`,
        },
      });
    }
  }, [step, guestCredentials, token, dispatch, updateOrder]);

  if (+step === 1) {
    return <ChooseLogin />;
  }

  if (+step === 2) {
    if (deliveryMethodId === 1) {
      return (
        <>
          <p className="mb-4 mt-4 font-main text-[16px] font-normal text-persianIndigo xl:text-[1.1vw]">
            {t('shipping_address')}
          </p>
          <p className="mb-4 font-secondary text-[14px] font-normal text-persianIndigo xl:text-[1vw]">
            {t(
              'in_case_you_do_not_know_the_recipient_address_please_fill_in_name_and_phone_number_and_we_will_send_them_a_form',
            )}
          </p>
          {!isGuestPaymentStep && (
            <AddressesList
              setSelectedAddress={setSelectedAddress}
              selectedAddress={selectedAddress}
            />
          )}
          <ShippingAddressForm
            buttonTitle={'continue'}
            type={'checkOut'}
            selectedAddress={selectedAddress}
          />
        </>
      );
    } else {
      return <PickupTime />;
    }
  }

  if (+step === 3) {
    return <ShippingTime />;
  }

  if (+step === 4) {
    return <CreditCardsCarousel />;
  }
};

const CheckoutPage = () => {
  const {step} = useParams();
  const t = useTranslations();
  const locale = useLocale();
  const dispatch = useDispatch();
  const {applyCartAction} = useApplyCartActions();
  const token = useSelector(getToken);
  const guestCredentials = useSelector(getGuestCredentials);
  const router = useRouter();
  const cartItems = useSelector(getCart);
  const deliveryMethod = useSelector(getDeliveryMethodId);
  const isGuestPaymentStep = useSelector(getIsGuestPaymentStep);
  const isWalletPaymentChosen = useSelector(getIsWalletPaymentChosen);
  const cardAmount = useSelector(getCardAmount);
  const isApplePayment = useSelector(getIsApplePayment);
  const store = useSelector(getNearestStoreData);
  const isTablet = useMediaQuery({query: '(max-width: 1024px)'});
  const isMobile = useMediaQuery({query: '(max-width: 640px)'});
  const [isOpen, setIsOpen] = useState(true);
  const [showWrittenCards, setShowWrittenCards] = useState(false);
  const [showAddOns, setShowAddOns] = useState(false);
  const [isCouponApplied, setIsCouponApplied] = useState(false);
  const [writtenCardData, setWrittenCardData] = useState(null);
  const [showLoginMessage, setShowLoginMessage] = useState(false);
  const [isGiftCardShown, setIsGiftCardShown] = useState(false);
  const [chosenGiftCard, setChosenGiftCard] = useState();
  const {data: allCategories, isError: getAllCategoriesError} =
    useGetAllCategoriesQuery(
      {include: 'image', locale, per_page: 100},
      commonDisableCachingParameters,
    );

  const addonsCategory = useMemo(() => {
    return allCategories?.data?.find(category => category.attributes.addons);
  }, [allCategories]);
  const {data: addOns, error: getAddOnsError} = useGetAddOnsQuery(
    {
      locale,
      'filter[branches]': store?.id,
      per_page: 100,
      'filter[taxons]': addonsCategory?.id,
    },
    commonDisableCachingParameters,
  );

  const [applyCouponCode] = useAddCouponCodeMutation();
  const [deleteCouponCode] = useRemoveCouponCodeMutation();
  const {data: userData} = useGetUserWithAddressesQuery(
    {locale},
    {
      skip: isGuestPaymentStep || !token,
      ...commonDisableCachingParameters,
    },
  );

  const formik = useFormik({
    initialValues: {
      couponCode: '',
    },
    onSubmit: values => {
      onHandlingCouponCodeSubmitting(values.couponCode);
    },
  });

  const toggleSummary = () => {
    if (isMobile) {
      setIsOpen(!isOpen);
    }
  };

  const onHandlingCouponCodeSubmitting = async couponCode => {
    if (couponCode.length === 0) {
      showErrorToast(t('coupon_code_is_empty'));
    } else {
      const response = !isCouponApplied
        ? await applyCouponCode({
            coupon_code: couponCode,
          })
        : await deleteCouponCode(couponCode);
      if (response?.error) {
        showErrorToast(response?.error.error);
      }

      if (parseInt(response?.data.data.attributes.promo_total) < 0) {
        sendGTMEvent({event: 'apply_promo_trigger', coupon_value: couponCode});
      } else {
        sendGTMEvent({event: 'remove_promo_trigger', coupon_value: couponCode});
      }
    }
  };

  useEffect(() => {
    if (
      step !== '1' &&
      !token &&
      (!guestCredentials?.guestPhoneNumber ||
        !guestCredentials?.guestFullName ||
        !guestCredentials?.isDataValid)
    ) {
      router.push('/checkout/1');
    } else if (
      step === '1' &&
      (token ||
        (guestCredentials?.guestPhoneNumber && guestCredentials?.guestFullName,
        guestCredentials?.isDataValid))
    ) {
      router.push('/checkout/2');
    }
  }, [step, router, guestCredentials, token]);

  useEffect(() => {
    if (cartItems?.total === 0 || cartItems?.lineItems?.lenght === 0) {
      toast(t('your_cart_is_empty'));
      router.push('/');
    }
  }, []);

  useEffect(() => {
    if (cartItems?.couponCodes?.length > 0) {
      setIsCouponApplied(true);
      formik.setFieldValue('couponCode', cartItems?.couponCodes[0]?.code);
    } else {
      setIsCouponApplied(false);
    }
  }, [cartItems]);

  if (getAddOnsError) {
    throw new Error('Failed to load add-ons.');
  }

  const handlePayWithStoreCredits = () => {
    dispatch(setIsWalletPaymentChosen(!isWalletPaymentChosen));
    const cardAmount = cartItems?.total - userData?.user.walletBalance;
    dispatch(setCardAmount(cardAmount));
  };

  const walletBalanceDisplay = useMemo(() => {
    if (isWalletPaymentChosen) {
      const amount =
        cardAmount > 0 && userData?.user.walletBalance > 0
          ? userData.user.walletBalance
          : cartItems?.total;

      if (amount > 0) {
        return (
          <p className="mt-[2.125rem] font-secondary text-[16px] font-bold text-pink">
            -{convertEnglishNumbersToArabic(amount, locale)} {t('sar')}
          </p>
        );
      }
    }

    return null;
  }, [cardAmount, isWalletPaymentChosen, userData, cartItems]);
  const isUserHaveNoWalletCredit = useMemo(() => {
    return userData?.user.walletBalance === 0 || !userData?.user.walletBalance;
  }, [userData]);

  const onHandlingChosenGiftCard = cardId => {
    setChosenGiftCard(cardId);
  };

  const handleAddingToCart = () => {
    if (
      chosenGiftCard?.id &&
      !cartItems?.hasWrittenCard &&
      writtenCardData?.get('message') &&
      isGiftCardShown
    ) {
      applyCartAction('add', chosenGiftCard, 1);
    }

    if (writtenCardData?.get('message')) {
      const requestOptions = {
        method: 'POST',
        body: writtenCardData,
        redirect: 'follow',
      };

      fetch(
        `${process.env.NEXT_PUBLIC_API_URL}/api/v2/storefront/written_cards`,
        requestOptions,
      )
        .then(response => response.text())
        .catch(error => showErrorToast(t('video_upload_failed')));
    }
  };

  {
    return isMobile ? (
      <div className="relative flex flex-col justify-center bg-transparent pb-[10rem]">
        <div className="flex flex-col justify-between px-6">
          <div className="xl:w-[40vw]">
            <div className="relative mb-8 mt-[3rem] flex items-center space-x-1 font-main text-[12px] font-normal text-persianIndigo">
              <span className="font-main font-normal text-persianIndigo">
                {t('home')}
              </span>
              <span>&gt;</span>
              <span>{t('shopping_cart')}</span>
            </div>

            <h3 className="mb-4 font-main font-normal text-persianIndigo sm:text-[20px] xl:text-[2vw]">
              {t('checkout')}
            </h3>
            <div className="h-full">
              <Stepper currentStep={step} deliveryMethodId={deliveryMethod} />
            </div>
          </div>
          <div className="mt-[2rem] xl:w-[45vw]">
            <div className="h-full w-full">
              <div className="bg-lightPurple px-[2.5rem] pb-1 pt-4 text-persianIndigo">
                <div
                  className="mb-4 flex items-center justify-between"
                  onClick={toggleSummary}>
                  <div className="flex items-center gap-4">
                    {isOpen ? (
                      <IoIosArrowDown
                        size={15}
                        className="text-persianIndigo"
                      />
                    ) : (
                      <IoIosArrowForward
                        size={15}
                        className="text-persianIndigo"
                      />
                    )}
                    <p className="font-main font-medium sm:mb-4 sm:text-[14px] xl:text-[26px]">
                      {t('order_summary')}
                    </p>
                  </div>

                  <p className="font-main font-medium sm:mb-4 sm:text-[12px] xl:text-[24px]">
                    {convertEnglishNumbersToArabic(cartItems?.total, locale)}{' '}
                    {t('sar')}
                  </p>
                </div>
                {isOpen && (
                  <>
                    {cartItems?.lineItems?.map((item, index) => (
                      <div
                        key={index}
                        className="flex items-center justify-between">
                        <p className="font-secondary text-[12px] sm:font-normal xl:text-[16px] xl:font-normal">
                          {convertEnglishNumbersToArabic(item.quantity, locale)}{' '}
                          x {capitalizeEachWord(item.name)}
                          {item.optionsText ? ' - ' : ''}
                          {capitalizeEachWord(item.optionsText.split(' ')[1])}
                        </p>
                        <p className="font-secondary text-[12px] sm:font-normal xl:text-[16px] xl:font-normal">
                          {convertEnglishNumbersToArabic(item.total, locale)}{' '}
                          {t('sar')}
                        </p>
                      </div>
                    ))}
                    {+step > 2 && (
                      <div className="flex items-center justify-between">
                        <p className="font-secondary text-[12px] sm:font-normal xl:text-[16px] xl:font-normal">
                          {t('vat')}
                        </p>
                        <p className="font-secondary text-[12px] text-persianIndigo sm:font-normal xl:text-[16px] xl:font-normal">
                          {convertEnglishNumbersToArabic(
                            cartItems?.taxTotal,
                            locale,
                          )}{' '}
                          {t('sar')}
                        </p>
                      </div>
                    )}
                    {deliveryMethod === 1 && (
                      <div className="flex items-center justify-between">
                        <p className="font-secondary text-[12px] sm:font-normal xl:text-[16px] xl:font-normal">
                          {t('delivery_fees')}
                        </p>
                        <p className="font-secondary text-[12px] text-persianIndigo sm:font-normal xl:text-[16px] xl:font-normal">
                          {convertEnglishNumbersToArabic(
                            cartItems?.shipTotal,
                            locale,
                          )}{' '}
                          {t('sar')}
                        </p>
                      </div>
                    )}
                    {isCouponApplied && (
                      <div className="flex items-center justify-between">
                        <p className="font-secondary text-[12px] sm:font-normal xl:text-[16px] xl:font-normal">
                          {t('discount')}
                        </p>
                        <p className="font-secondary text-[12px] text-pink sm:font-normal xl:text-[16px] xl:font-normal">
                          {cartItems?.couponCodes[0]?.amount} {t('sar')}
                        </p>
                      </div>
                    )}
                    {+step === 4 && !isGuestPaymentStep && !isApplePayment && (
                      <>
                        <div className="flex items-center justify-between">
                          <div className="mt-[2.125rem] flex items-center gap-2">
                            <Checkbox
                              onChange={handlePayWithStoreCredits}
                              isDisabled={isUserHaveNoWalletCredit}
                              isSelected={isWalletPaymentChosen}
                              color="secondary">
                              <span className="font-secondary text-[12px] font-bold text-persianIndigo xl:text-[16px]">
                                {' '}
                                {t('available_credit')}
                              </span>
                            </Checkbox>
                            <p className="mt-[4px] font-secondary text-[12px] font-bold text-thistle xl:text-[16px]">
                              {convertEnglishNumbersToArabic(
                                userData?.user.walletBalance || 0,
                                locale,
                              )}{' '}
                              {t('sar')}
                            </p>
                          </div>
                          <div>{walletBalanceDisplay}</div>
                        </div>
                        {isWalletPaymentChosen && cardAmount > 0 && (
                          <p className="mt-2 font-secondary text-[12px] font-normal text-pink">
                            {t(
                              'please_choose_payment_card_to_pay_the_remaining_amount',
                            )}{' '}
                            {convertEnglishNumbersToArabic(cardAmount, locale)}{' '}
                            {t('sar')}
                          </p>
                        )}
                      </>
                    )}
                    <form onSubmit={formik.handleSubmit}>
                      <div className="mb-3 mt-[3rem] font-secondary">
                        <Input
                          type="text"
                          variant="underlined"
                          className="w-full text-[16px]"
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          onFocus={() => !token && setShowLoginMessage(true)}
                          name="couponCode"
                          readOnly={isCouponApplied}
                          placeholder={t('enter_coupon_code_here')}
                          value={formik.values.couponCode}
                          classNames={{
                            label: '!text-thistle',
                            inputWrapper:
                              '!border-thistle hover:border-thistle focus:border-thistle focus:border-[5px] !outline-0 after:bg-persianIndigo',
                            input: `${isCouponApplied ? '!text-pink' : '!text-persianIndigo'} placeholder:text-thistle`,
                          }}
                          endContent={
                            <CustomButton
                              btnActionType="submit"
                              btnType="tertiary"
                              isDisabled={!token}
                              value={isCouponApplied ? t('remove') : t('apply')}
                              btnStyles={`${isCouponApplied ? 'text-pink underline' : ''} text-[12px] font-secondary hover:!bg-transparent px-0 !min-w-fit hover:underline transition`}
                            />
                          }
                        />
                        {!token && showLoginMessage && (
                          <div
                            className={`mt-2 font-secondary text-[12px] text-red-500`}>
                            <span>{t('please')} </span>
                            <span
                              className="cursor-pointer underline"
                              onClick={() => dispatch(openLogIn())}>
                              {t('login')}{' '}
                            </span>
                            <span>{t('first_to_use_coupons')}</span>
                          </div>
                        )}
                      </div>
                    </form>
                  </>
                )}
              </div>
              <div className="mt-2 bg-lightPurple py-4">
                <div
                  className="mb-4 flex cursor-pointer items-center gap-4 px-[2.5rem]"
                  onClick={() => setShowWrittenCards(!showWrittenCards)}>
                  {showWrittenCards ? (
                    <FaMinus size={15} className="text-persianIndigo" />
                  ) : (
                    <FaPlus size={15} className="text-persianIndigo" />
                  )}
                  <p className="font-main text-persianIndigo sm:text-[14px] sm:font-normal xl:text-[21px] xl:font-medium">
                    {t('add_gift_card')}
                  </p>
                </div>
                {showWrittenCards && (
                  <div className="px-4">
                    <CheckoutGiftCard
                      setWrittenCardData={setWrittenCardData}
                      isGiftCardShown={isGiftCardShown}
                      setIsGiftCardShown={setIsGiftCardShown}
                      onHandlingChosenGiftCard={onHandlingChosenGiftCard}
                    />
                    <CustomButton
                      value={t('add_to_cart')}
                      onClickHandling={handleAddingToCart}
                      btnStyles="w-full xl:w-[16.875rem] h-[2rem] text-[12px]"
                    />
                  </div>
                )}
              </div>
              {addOns?.taxons?.length > 0 && (
                <div
                  className={`${locale === 'ar' ? 'pr-[2.5rem]' : 'pl-[2.5rem]'} sm:pl-auto mt-2 bg-lightPurple pb-1 pt-4 sm:px-[2.5rem]`}>
                  <div
                    className="mb-4 flex cursor-pointer items-center gap-4"
                    onClick={() => setShowAddOns(!showAddOns)}>
                    {showAddOns ? (
                      <FaMinus size={15} className="text-persianIndigo" />
                    ) : (
                      <FaPlus size={15} className="text-persianIndigo" />
                    )}
                    <p className="font-main text-persianIndigo sm:text-[14px] sm:font-normal xl:text-[21px] xl:font-medium">
                      {t('recommended_add_ons')}
                    </p>
                  </div>
                  {showAddOns ? (
                    <RecommendedAddOns t={t} addOns={addOns} />
                  ) : null}
                </div>
              )}
            </div>
          </div>
          <CheckoutStep />
        </div>{' '}
      </div>
    ) : (
      <div className="relative flex flex-col justify-center bg-transparent pb-[10rem] pt-4">
        <Image
          className={`${locale === 'ar' ? 'sm:right-[7.063rem] xl:right-[20.063rem]' : 'sm:left-[7.063rem] xl:left-[20.063rem]'} absolute top-0 z-[-1] hidden sm:block`}
          src={thirdFlower}
          width={200}
          height={160}
          alt="background flower"
        />
        <div className="flex justify-center sm:mx-[3rem] sm:gap-[50px] xl:mx-[13.5vw] xl:justify-between xl:gap-[4.5rem]">
          <div className="sm:w-full xl:w-[40vw]">
            {isTablet ? (
              <div className="relative mb-8 flex items-center space-x-1 font-main text-[12px] font-normal text-persianIndigo">
                <span className="font-main font-normal text-persianIndigo">
                  {t('home')}
                </span>
                <span>&gt;</span>
                <span>{t('shopping_cart')}</span>
              </div>
            ) : (
              <div>
                <p className="mb-2 font-main font-normal text-persianIndigo sm:text-[12px] xl:text-[0.9vw]">
                  {t('customer_info')}
                </p>
              </div>
            )}

            <h3 className="mb-4 font-main font-normal text-persianIndigo sm:text-[20px] xl:text-[2vw]">
              {t('checkout')}
            </h3>
            <div className="h-full">
              <Stepper currentStep={step} deliveryMethodId={deliveryMethod} />
              <CheckoutStep />
            </div>
          </div>
          <div className="mt-[5.5rem] sm:sticky sm:top-[156px] sm:h-full sm:w-full xl:w-[45vw]">
            <div className="h-full w-full">
              <div className="bg-lightPurple px-[2.5rem] py-[2rem] text-persianIndigo">
                <div className="flex items-center justify-between">
                  <p className="mb-4 font-main font-medium sm:text-[14px] xl:text-[26px]">
                    {t('order_summary')}
                  </p>
                  <p className="mb-4 font-main font-medium sm:text-[12px] xl:text-[24px]">
                    {convertEnglishNumbersToArabic(cartItems?.total, locale)}{' '}
                    {t('sar')}
                  </p>
                </div>
                {cartItems?.lineItems?.map((item, index) => (
                  <div
                    key={index}
                    className="flex items-center justify-between">
                    <p className="font-secondary text-[12px] sm:font-normal xl:text-[16px] xl:font-normal">
                      {convertEnglishNumbersToArabic(item.quantity, locale)} x{' '}
                      {capitalizeEachWord(item.name)}
                      {item.optionsText ? ' - ' : ''}
                      {capitalizeEachWord(item.optionsText.split(' ')[1])}
                    </p>
                    <p className="font-secondary text-[12px] sm:font-normal xl:text-[16px] xl:font-normal">
                      {convertEnglishNumbersToArabic(item.total, locale)}{' '}
                      {t('sar')}
                    </p>
                  </div>
                ))}
                {+step > 2 && (
                  <div className="flex items-center justify-between">
                    <p className="font-secondary text-[12px] sm:font-normal xl:text-[16px] xl:font-normal">
                      {t('vat')}
                    </p>
                    <p className="font-secondary text-[12px] text-persianIndigo sm:font-normal xl:text-[16px] xl:font-normal">
                      {convertEnglishNumbersToArabic(
                        cartItems?.taxTotal,
                        locale,
                      )}{' '}
                      {t('sar')}
                    </p>
                  </div>
                )}
                {deliveryMethod === 1 && (
                  <div className="flex items-center justify-between">
                    <p className="font-secondary text-[12px] sm:font-normal xl:text-[16px] xl:font-normal">
                      {t('delivery_fees')}
                    </p>
                    <p className="font-secondary text-[12px] text-persianIndigo sm:font-normal xl:text-[16px] xl:font-normal">
                      {convertEnglishNumbersToArabic(
                        cartItems?.shipTotal,
                        locale,
                      )}{' '}
                      {t('sar')}
                    </p>
                  </div>
                )}
                {isCouponApplied && (
                  <div className="flex items-center justify-between">
                    <p className="font-secondary text-[12px] sm:font-normal xl:text-[16px] xl:font-normal">
                      {t('discount')}
                    </p>
                    <p className="font-secondary text-[12px] text-pink sm:font-normal xl:text-[16px] xl:font-normal">
                      {cartItems?.couponCodes[0]?.amount} {t('sar')}
                    </p>
                  </div>
                )}
                {+step === 4 && !isGuestPaymentStep && !isApplePayment && (
                  <>
                    <div className="flex items-center justify-between">
                      <div className="mt-[2.125rem] flex items-center gap-2">
                        <Checkbox
                          onChange={handlePayWithStoreCredits}
                          isSelected={isWalletPaymentChosen}
                          isDisabled={isUserHaveNoWalletCredit}
                          color="secondary">
                          <span className="font-secondary text-[12px] font-bold text-persianIndigo xl:text-[16px]">
                            {' '}
                            {t('available_credit')}
                          </span>
                        </Checkbox>
                        <p className="font-secondary text-[12px] font-bold text-thistle xl:text-[16px]">
                          {convertEnglishNumbersToArabic(
                            userData?.user.walletBalance || 0,
                            locale,
                          )}{' '}
                          {t('sar')}
                        </p>
                      </div>
                      <div>{walletBalanceDisplay}</div>
                    </div>
                    {isWalletPaymentChosen && cardAmount > 0 && (
                      <p className="mt-2 font-secondary text-[10px] font-normal text-pink">
                        {t(
                          'please_choose_payment_card_to_pay_the_remaining_amount',
                        )}{' '}
                        {convertEnglishNumbersToArabic(cardAmount, locale)}{' '}
                        {t('sar')}
                      </p>
                    )}
                  </>
                )}
                <form onSubmit={formik.handleSubmit}>
                  <div className="mb-3 mt-[3rem] font-secondary">
                    <Input
                      type="text"
                      variant="underlined"
                      className="w-full text-[16px]"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      onFocus={() => !token && setShowLoginMessage(true)}
                      name="couponCode"
                      readOnly={isCouponApplied}
                      placeholder={t('enter_coupon_code_here')}
                      value={formik.values.couponCode}
                      classNames={{
                        label: '!text-thistle',
                        inputWrapper:
                          '!border-thistle hover:border-thistle focus:border-thistle focus:border-[5px] !outline-0 after:bg-persianIndigo',
                        input: `${isCouponApplied ? '!text-pink' : '!text-persianIndigo'} placeholder:text-thistle`,
                      }}
                      endContent={
                        <CustomButton
                          btnActionType="submit"
                          btnType="tertiary"
                          isDisabled={!token}
                          value={isCouponApplied ? t('remove') : t('apply')}
                          btnStyles={`${isCouponApplied ? 'text-pink underline' : ''} text-[12px] font-secondary hover:!bg-transparent px-0 !min-w-fit hover:underline transition`}
                        />
                      }
                    />
                    {!token && showLoginMessage && (
                      <div
                        className={`mt-2 font-secondary text-[12px] text-red-500`}>
                        <span>{t('please')} </span>
                        <span
                          className="cursor-pointer underline"
                          onClick={() => dispatch(openLogIn())}>
                          {t('login')}{' '}
                        </span>
                        <span>{t('first_to_use_coupons')}</span>
                      </div>
                    )}
                  </div>
                </form>
              </div>
              <div className="mt-2 bg-lightPurple py-4">
                <div
                  className="mb-4 flex cursor-pointer items-center gap-4 px-[2.5rem]"
                  onClick={() => setShowWrittenCards(!showWrittenCards)}>
                  {showWrittenCards ? (
                    <FaMinus size={15} className="text-persianIndigo" />
                  ) : (
                    <FaPlus size={15} className="text-persianIndigo" />
                  )}
                  <p className="font-main text-persianIndigo sm:text-[14px] sm:font-normal xl:text-[21px] xl:font-medium">
                    {t('add_gift_card')}
                  </p>
                </div>
                {showWrittenCards && (
                  <div className="flex flex-col items-center px-4">
                    <CheckoutGiftCard
                      setWrittenCardData={setWrittenCardData}
                      isGiftCardShown={isGiftCardShown}
                      setIsGiftCardShown={setIsGiftCardShown}
                      onHandlingChosenGiftCard={onHandlingChosenGiftCard}
                    />
                    <CustomButton
                      value={t('add_to_cart')}
                      onClickHandling={handleAddingToCart}
                      btnStyles="w-full xl:w-[16.875rem] h-[2rem] text-[12px]"
                    />
                  </div>
                )}
              </div>
              {addOns?.taxons?.length > 0 && (
                <div className="mt-2 bg-lightPurple pb-1 pt-4">
                  <div
                    className="mb-4 flex cursor-pointer items-center gap-4 px-[2.5rem]"
                    onClick={() => setShowAddOns(!showAddOns)}>
                    {showAddOns ? (
                      <FaMinus size={15} className="text-persianIndigo" />
                    ) : (
                      <FaPlus size={15} className="text-persianIndigo" />
                    )}
                    <p className="font-main text-persianIndigo sm:text-[14px] sm:font-normal xl:text-[21px] xl:font-medium">
                      {t('recommended_add_ons')}
                    </p>
                  </div>
                  {showAddOns ? (
                    <RecommendedAddOns t={t} addOns={addOns} />
                  ) : null}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default CheckoutPage;
