'use client';

import {rules, showErrorToast, showSuccessToast} from '@/common';
import {
  getCart,
  getIsNewPasswordUpdateModalOpen,
  getUserPhoneNumber,
} from '@/selectors';
import {
  useAssociateCartMutation,
  useLoginMutation,
  useResetPasswordMutation,
} from '@/services';
import {
  closeNewPasswordUpdateModal,
  openNewPasswordUpdateModal,
  setUserPhone,
} from '@/slices';
import {
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from '@heroui/react';
import {useFormik} from 'formik';
import {useTranslations} from 'next-intl';
import {useState} from 'react';
import {FaRegEye, FaRegEyeSlash} from 'react-icons/fa';
import {useDispatch, useSelector} from 'react-redux';
import * as Yup from 'yup';
import {CustomButton} from '../common';

const NewPasswordModal = () => {
  const dispatch = useDispatch();
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isPasswordConfirmationVisible, setIsPasswordConfirmationVisible] =
    useState(false);
  const isNewPasswordModalOpen = useSelector(getIsNewPasswordUpdateModalOpen);
  const t = useTranslations();
  const userPhone = useSelector(getUserPhoneNumber);
  const cart = useSelector(getCart);
  const [updatePassword] = useResetPasswordMutation();
  const [onLogin] = useLoginMutation();
  const [associateCart] = useAssociateCartMutation();

  const validationSchema = Yup.object({
    password: rules('password', null, t),
    passwordConfirmation: rules('passwordConfirmation', null, t),
  });

  const formik = useFormik({
    initialValues: {
      password: '',
      passwordConfirmation: '',
    },
    validationSchema,
    onSubmit: async values => {
      const response = await updatePassword({
        username: userPhone,
        password: values.password,
        password_confirmation: values.passwordConfirmation,
      });
      if (response.error) {
        showErrorToast(response?.error.error);
      } else {
        showSuccessToast(t('password_updated_successfully'));
        onClosingNewPasswordUpdateModal();
        onLogingIn(values.password);
      }
    },
  });

  const togglePasswordVisibility = () =>
    setIsPasswordVisible(!isPasswordVisible);
  const togglePasswordConfirmationVisibility = () =>
    setIsPasswordConfirmationVisible(!isPasswordConfirmationVisible);

  const onLogingIn = async newPassword => {
    const response = await onLogin({
      username: userPhone,
      password: newPassword,
      grant_type: 'password',
    });

    if (response?.error) {
      showErrorToast(response?.error.error);
    } else {
      dispatch(setUserPhone(null));
      await associateCart({
        guest_order_token: cart?.token,
      });
    }
  };

  const onOpeningNewPasswordUpdateModal = () => {
    dispatch(openNewPasswordUpdateModal());
  };
  const onClosingNewPasswordUpdateModal = () => {
    formik.resetForm();
    dispatch(closeNewPasswordUpdateModal());
  };

  return (
    <>
      <div>
        <Modal
          isOpen={isNewPasswordModalOpen}
          size="2xl"
          className="mx-4 my-auto p-12"
          onOpenChange={onOpeningNewPasswordUpdateModal}
          onClose={onClosingNewPasswordUpdateModal}
          closeButton={
            <CustomButton
              btnType="tertiary"
              value={'X'}
              onClickHandling={onClosingNewPasswordUpdateModal}
            />
          }
          classNames={{
            closeButton:
              '!text-[22px] !text-persianIndigo hover:!bg-thistle font-main !p-0 !rounded-md mt-4 hidden sm:block',
          }}>
          <ModalContent>
            {onClose => (
              <>
                <ModalHeader className="justify-center">
                  <span className="w-[16.25rem] border-b-4 border-thistle pb-2 text-center font-main text-[20px] font-medium text-persianIndigo sm:text-[26px]">
                    {t('new_password')}
                  </span>
                </ModalHeader>
                <form onSubmit={formik.handleSubmit}>
                  <ModalBody className="!px-0">
                    <div className="flex w-full flex-col">
                      <div className="mb-3 font-secondary">
                        <Input
                          type={isPasswordVisible ? 'text' : 'password'}
                          variant="underlined"
                          name="password"
                          label={t('new_password')}
                          value={formik.values.password}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          endContent={
                            <button
                              className="focus:outline-none"
                              type="button"
                              onClick={togglePasswordVisibility}>
                              {isPasswordVisible ? (
                                <div className="rounded-full bg-thistle p-1">
                                  <FaRegEyeSlash className="pointer-events-none text-2xl text-[16px] text-white" />
                                </div>
                              ) : (
                                <div className="rounded-full bg-thistle p-1">
                                  <FaRegEye className="pointer-events-none text-2xl text-[16px] text-white" />
                                </div>
                              )}
                            </button>
                          }
                          className="w-full text-[16px]"
                          classNames={{
                            inputWrapper:
                              '!border-thistle hover:border-thistle focus:border-thistle focus:border-[5px] !outline-0 after:bg-persianIndigo',
                            input: '!text-persianIndigo',
                            label: '!text-thistle',
                          }}
                        />
                        {formik.touched.password && formik.errors.password ? (
                          <div
                            className={`mt-2 font-secondary text-[12px] text-red-500`}>
                            {formik.errors.password}
                          </div>
                        ) : null}
                      </div>
                      <div className="mb-3 w-full font-secondary">
                        <Input
                          label={t('password_confirmation')}
                          variant="underlined"
                          endContent={
                            <button
                              className="focus:outline-none"
                              type="button"
                              onClick={togglePasswordConfirmationVisibility}>
                              {isPasswordConfirmationVisible ? (
                                <div className="rounded-full bg-thistle p-1">
                                  <FaRegEyeSlash className="pointer-events-none text-2xl text-[16px] text-white" />
                                </div>
                              ) : (
                                <div className="rounded-full bg-thistle p-1">
                                  <FaRegEye className="pointer-events-none text-2xl text-[16px] text-white" />
                                </div>
                              )}
                            </button>
                          }
                          type={
                            isPasswordConfirmationVisible ? 'text' : 'password'
                          }
                          name="passwordConfirmation"
                          value={formik.values.passwordConfirmation}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          classNames={{
                            label: '!text-thistle',
                            inputWrapper:
                              '!border-thistle hover:border-thistle focus:border-thistle focus:border-[5px] !outline-0 after:bg-persianIndigo',
                            input: '!text-persianIndigo',
                          }}
                        />
                        {formik.touched.passwordConfirmation &&
                        formik.errors.passwordConfirmation ? (
                          <div
                            className={`mt-2 font-secondary text-[12px] text-red-500`}>
                            {formik.errors.passwordConfirmation}
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </ModalBody>
                  <ModalFooter className="!px-0">
                    <CustomButton
                      btnActionType="submit"
                      btnStyles={'w-full'}
                      value={t('change')}
                      isDisabled={!formik.isValid || !formik.dirty}
                    />
                  </ModalFooter>
                </form>
              </>
            )}
          </ModalContent>
        </Modal>
      </div>
    </>
  );
};

export default NewPasswordModal;
